import { createStyles, Theme } from '@material-ui/core';

export default ({ spacing }: Theme) =>
  createStyles({
    popupContent: {
      position: 'absolute',
      top: '1px',
      left: '1px',
      height: '100vh',
      width: '100vw',
      // zIndex: 2,
      background: 'rgba(31,31,31, 0.34)',
      outline: 'none',
    },
    showed: {
      display: 'block',
    },
    hidden: {
      display: 'none',
    },

    root: {
      flexGrow: 1,
    },
    paper: {
      padding: spacing(11, 11.5),
      margin: 'auto',
      maxWidth: '650px',
      minWidth: '482px',
    },
    buttonsSection: {
      paddingTop: 24,
    },
    submitButton: {
      maxHeight: 40,
      minWidth: 212,
    },
    childContent: {
      paddingTop: spacing(6),
    },
    muiModal: {
      // paper: {
      //   outline: 0
      // }
    },
  });
