export enum ALERT_TYPE {
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success',
}

export interface AlertDTO {
  type: ALERT_TYPE;
  message: string;
}

export class Alert {
  type: ALERT_TYPE;
  message: string;

  constructor(dto: AlertDTO) {
    this.update(dto);
  }

  get asJson(): AlertDTO {
    return {
      type: this.type,
      message: this.message,
    };
  }

  update(newData: Partial<AlertDTO>): AlertDTO {
    const allData = { ...this.asJson, ...newData };

    Object.assign(this, allData);

    return this.asJson;
  }
}
