import { injectable } from 'inversify';
import BaseService from '@core/services/base';
import Axios from 'axios';
import { ActivateSubscription, ActivateSubscriptionDTO } from '../models/activatesubscription';

@injectable()
export default class ClientSubscriptionsService extends BaseService<
  ActivateSubscriptionDTO,
  ActivateSubscription
> {
  static diToken = Symbol('client-subscriptions-service');

  constructor() {
    super({
      domainArea: 'client-primary-accounts',
      Model: ActivateSubscription,
      collection: 'clientprimaryaccount',
    });
  }

  createSubscription(subscriptionDetails: ActivateSubscription) {
    return Axios.post(this.getURL('current/subscriptions'), subscriptionDetails);
  }

  updateSubscription(subscriptionDetails: ActivateSubscription) {
    return Axios.put(this.getURL('current/subscriptions'), subscriptionDetails);
  }
}
