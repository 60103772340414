import { injectable } from 'inversify';

import container from '@core/di';

import BaseStore from '@core/stores/base';
import { UserAccount, UserAccountDTO } from '@shared/models/useraccount';
import UserAccountsService from '@shared/services/useraccounts';
import { action } from 'mobx';
import { ListRequestParams } from '@shared/types/services';

@injectable()
export default class UserAccountStore extends BaseStore<
  UserAccountDTO,
  UserAccount,
  UserAccountsService
> {
  static diToken = Symbol('useraccounts-store');

  constructor() {
    super({ service: container.get<UserAccountsService>(UserAccountsService.diToken) });

    this.loading = {
      list: true,
      item: false,
    };
  }

  @action getAllClinicians = async () => {
    return this.service.getAllClinicians();
  };

  @action getList = async (params: ListRequestParams) => {
    this.loading.list = true;

    try {
      const { items, pageInfo } = await this.service.getAccountsList(params);

      this._list = items;
      this._pageInfo = pageInfo;

      return {
        items,
        pageInfo,
      };
    } finally {
      this.loading.list = false;
    }
  };

  @action changeRole = async (ssoId: string, data) => {
    return this.service.changeRole(ssoId, data);
  };

  @action deactivateAccount = async (ssoId: string) => {
    return this.service.deactivateAccount(ssoId);
  };

  @action activateAccount = async (ssoId: string) => {
    return this.service.activateAccount(ssoId);
  };

  @action reinviteUser = async (ssoId: string) => {
    return this.service.reinviteUser(ssoId);
  };
}
