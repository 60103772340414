import { injectable } from 'inversify';
import BaseService from '@core/services/base';
import { ListRequestParams, ListResponse } from '@shared/types/services';
import { SortingType } from '@shared/constants/services';
import { LifeQualityItem, LifeQualityItemDTO } from '@shared/models/lifequality';
import Axios from 'axios';
import { getQueries } from '@shared/utils/common';

export interface GetLifeQualityReportsListParams {
  Page?: number;
  PageSize?: number;
  LifeQualityQuestionType?: string;
  ClientPrimaryAccountId?: string;
  'Sort.Field'?: string;
  'Sort.Type'?: SortingType;
}

@injectable()
export default class LifequalityService extends BaseService<LifeQualityItemDTO, LifeQualityItem> {
  static diToken = Symbol('lifequality-service');

  constructor() {
    super({
      domainArea: 'life-quality-report',
      Model: LifeQualityItem,
      collection: 'lifequalityreport',
    });
  }

  async getLifeQualityReportsList(params?: GetLifeQualityReportsListParams) {
    const { data } = await Axios.get<ListResponse<LifeQualityItemDTO>>(this.urlPrefix, {
      params: params ?? this.getLifeQualityReportsListParams(),
    });

    const { items, pageInfo } = data;

    return {
      pageInfo,
      items: items.map(dto => new LifeQualityItem(dto)),
    };
  }

  private getLifeQualityReportsListParams(): GetLifeQualityReportsListParams {
    const queries: ListRequestParams = getQueries();

    return {
      'Sort.Field': queries.sorting?.field,
      'Sort.Type': queries.sorting?.type,
      Page: queries.pagination?.page,
      PageSize: queries.pagination?.pageSize,
      ClientPrimaryAccountId: queries.lifeQuality?.clientPrimaryAccountId || undefined,
      LifeQualityQuestionType: queries.lifeQuality?.lifeQualityQuestionType || undefined,
    };
  }
}
