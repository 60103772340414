import { injectable } from 'inversify';
import container from '@core/di';
import BaseStore from '@core/stores/base';
import { action, observable, computed } from 'mobx';
import AssessmentsEvaluationsService from '@shared/services/assessmentsevaluations';
import ClientPrimaryService from '@shared/services/client-primary';
import { Assessment, AssessmentDTO } from '@shared/models/assessment';
import { AssessmentsMatrixDTO } from '@shared/models/matrix';
import { AssessmentNotes } from '@shared/models/assesmentnotes';
import {
  CategoriesEvaluations,
  DomainsEvaluations,
  BrainFunctionEvaluation,
  Evaluation,
  ChildAgeModel,
  AbilitiesNote,
  AdditionalCriteriaNote,
  EvaluationReviewStatus,
} from '@shared/models/assessmentdetails';
import { ClinicalCriteriaDTO, AdditionalCriteriaType } from '@shared/models/clinicalcriteria';
import {
  AssessmentModelFactory,
  IAssessmentModelFactory,
} from '@Admin/factories/AssessmentModelFactory';
import { AgeLevelType } from '@shared/models/evaluations';
import { Domain } from '@shared/models/assessmentdetails';
import { getMonthsBetweenDates } from '@shared/utils/date';
import { ClientPrimary } from '@shared/models/client-primary';
import { IGetCurrentStatusResponse } from '@shared/types/services';
import { ASSESSMENT_OWNER } from '@shared/models/assessment-status';

@injectable()
export default class AssessmentEvaluationStore extends BaseStore<
  AssessmentDTO,
  Assessment,
  AssessmentsEvaluationsService
> {
  static diToken = Symbol('assessments-evaluations-store');
  private assessmentModelFactory = container.get<IAssessmentModelFactory>(
    AssessmentModelFactory.diToken,
  );
  private clientPrimaryService = container.get<ClientPrimaryService>(ClientPrimaryService.diToken);

  @observable private _assessmentsMatrix: AssessmentsMatrixDTO = {
    brainFunctions: [],
    brainLevels: [],
  };
  @observable private _clinicalCriteria: ClinicalCriteriaDTO[] = [];
  @observable private _existsAssessmentTemplate = false;
  @observable private _assessmentEvaluationModel: CategoriesEvaluations[] = [];
  @observable private _additionalCriteriasEvaluationModel: AdditionalCriteriaType[] = [];
  @observable private _assessmentNotesHistory: AssessmentNotes[] = [];
  @observable private _ageLevelEight = {} as ChildAgeModel;
  @observable private _isLoading = false;
  @observable private _isValidEvaluation = false;
  @observable private _isValidCriteria = false;
  @observable private _isValidAge = false;
  @observable private _age = 0;
  @observable private _assessmentStatus: IGetCurrentStatusResponse | null = null;

  constructor() {
    super({
      service: container.get<AssessmentsEvaluationsService>(AssessmentsEvaluationsService.diToken),
    });
  }

  @computed get assessmentEvaluationModel() {
    return this._assessmentEvaluationModel;
  }
  @computed get additionalCriteriasEvaluationModel() {
    return this._additionalCriteriasEvaluationModel;
  }
  @computed get ageLevelEight() {
    return this._ageLevelEight;
  }
  @computed get assessmentNotesHistory() {
    return this._assessmentNotesHistory;
  }
  @computed get isLoading() {
    return this._isLoading;
  }
  @computed get isValidAssessment() {
    return !this._isValidEvaluation || !this._isValidCriteria || !this._isValidAge;
  }

  @computed get assessmentEvaluationReviewData() {
    return this._assessmentEvaluationModel.reduce<Record<number, Evaluation[]>>((acc, cur, i) => {
      const domainEvaluations = Object.values(cur).filter((ev): ev is DomainsEvaluations =>
        Boolean(ev),
      );
      const evaluations = domainEvaluations.reduce<Evaluation[]>((accEv, curEv) => {
        return [
          ...accEv,
          ...Object.values(curEv)
            .map(d => d.evaluations)
            .flat()
            .map(e => e.evaluation),
        ];
      }, []);

      return {
        ...acc,
        [i]: [...evaluations],
      };
    }, {});
  }

  @computed get levelsReviewed(): Record<number, boolean> {
    const status = this._assessmentStatus;

    const isReviewedFn = (e: Evaluation | AdditionalCriteriaType) => {
      if (status?.assessmentOwnedBy === ASSESSMENT_OWNER.ClientPrimaryAccount) {
        return e?.reviewStatus === EvaluationReviewStatus.ReviewerCompleted;
      }

      if (
        status?.assessmentOwnedBy === ASSESSMENT_OWNER.Clinician &&
        status?.isSubsequentAssessment
      ) {
        return e?.reviewStatus === EvaluationReviewStatus.EvaluatorCompleted;
      }

      return true;
    };

    const levels = Object.fromEntries(
      Object.entries(this.assessmentEvaluationReviewData).map(([i, ev]) => [
        i,
        ev.every(isReviewedFn),
      ]),
    );

    return {
      ...levels,
      8: this._additionalCriteriasEvaluationModel.every(isReviewedFn),
    };
  }

  @computed get isReviewedAssessment() {
    return Object.values(this.levelsReviewed).every(Boolean);
  }

  @action getAssessmentTemplate = async () => {
    try {
      this._isLoading = true;

      this._assessmentsMatrix = await this.service.getMatrixAssessments();
      this._clinicalCriteria = await this.service.getClinicalCriteria();

      this._existsAssessmentTemplate = true;
    } catch (err) {
      this._isLoading = false;
    } finally {
      this._isLoading = false;
    }
  };

  @action getEvaluations = async (assessmentId: number) => {
    try {
      const evaluations = await this.service.getEvaluations(assessmentId);
      const notes = await this.service.getAbilitiesNotes(assessmentId);

      const tabs = this.assessmentModelFactory.createAssessmentEvaluationModel(
        this._assessmentsMatrix,
        evaluations,
        notes,
      );

      const ageLevelEight = {} as ChildAgeModel;
      evaluations.forEach(item =>
        Object.assign(ageLevelEight, {
          [item.brainDomain]: item.functionalAgeAtLevel8 || this._age,
        }),
      );

      this._assessmentEvaluationModel = tabs;
      this._ageLevelEight = ageLevelEight;
      this._isValidEvaluation = this.isValidAssessmentEvaluation(tabs);
      this._isValidAge = this.isValidChildAge(tabs, ageLevelEight);
    } catch (error) {
      console.log('getEvaluations error', error);

      return new Promise(error);
    }
  };

  @action getAdditionalCriteria = async (assessmentId: number) => {
    const notes = await this.service.getAdditionalCriteriaNotes(assessmentId);

    try {
      const additionalCriteria = await this.service.getCurrentClinicalCriteria(assessmentId);
      const additionalTabs = this.assessmentModelFactory.createAdditionalCriteriasEvaluationModel(
        this._clinicalCriteria,
        additionalCriteria,
        notes,
      );

      this._additionalCriteriasEvaluationModel = additionalTabs;
      this._isValidCriteria = this.isValidAdditionalCriteria(additionalTabs);
    } catch (error) {
      const status = error.response?.status;
      if (status === 404) {
        const additionalTabs = this.assessmentModelFactory.createAdditionalCriteriasEvaluationModel(
          this._clinicalCriteria,
          [],
          notes,
        );
        this._additionalCriteriasEvaluationModel = additionalTabs;
        this._isValidCriteria = false;
      }
      throw error;
    }
  };

  @action getCurrentAssessment = async (
    assessmentId: number,
    clientPrimaryAccountId: number,
    childId: string,
    onFetchedClientPrimaryAccount?: (acc: ClientPrimary) => Promise<void>,
  ) => {
    if (!this._existsAssessmentTemplate) {
      this.getAssessmentTemplate();
    }

    const clientPrimaryAccount = await this.clientPrimaryService.get(clientPrimaryAccountId);
    onFetchedClientPrimaryAccount?.(clientPrimaryAccount);
    this._age = getMonthsBetweenDates(clientPrimaryAccount?.child?.birthDate);

    this._isLoading = true;

    try {
      await this.getEvaluations(assessmentId);
      await this.getAdditionalCriteria(assessmentId);
      this._assessmentStatus = await this.getAssessmentStatus(
        String(clientPrimaryAccountId),
        Number(childId),
      );
    } finally {
      this._isLoading = false;
    }
  };

  @action editEvaluationRating = async (
    clientPrimaryAccountId: number,
    childId: number,
    selectTab: number,
    category: string,
    domain: string,
    evaluation: Evaluation,
  ) => {
    const { brainFunctionId, evaluationRating, reviewStatus } = evaluation;

    const evaluations = this._assessmentEvaluationModel[selectTab][category][
      domain
    ].evaluations.map(item => {
      if (item.id === brainFunctionId) {
        return {
          ...item,
          evaluation: {
            brainFunctionId,
            evaluationRating,
            reviewStatus,
          },
          evaluationHistory: {
            brainFunctionId,
            evaluationRating:
              item?.evaluationHistory?.evaluationRating ?? item.evaluation?.evaluationRating,
          },
        };
      }

      return item;
    });

    const markedAsNotYetAchieved =
      this._assessmentEvaluationModel[selectTab][category][domain].markedAsNotYetAchieved;
    const domainDescription =
      this._assessmentEvaluationModel[selectTab][category][domain].domainDescription;

    this._assessmentEvaluationModel[selectTab][category][domain] = new Domain(
      evaluations,
      markedAsNotYetAchieved,
      domainDescription,
    );

    this._isValidEvaluation = this.isValidAssessmentEvaluation(this._assessmentEvaluationModel);
    this._isValidAge = this.isValidChildAge(this._assessmentEvaluationModel, this._ageLevelEight);

    await this.service.editEvaluationRating(clientPrimaryAccountId, childId, {
      brainFunctionEvaluations: [
        {
          brainFunctionId,
          evaluationRating,
          ...(reviewStatus ? { reviewStatus } : {}),
        },
      ],
    });
  };

  @action reviewBrainFunctions = async (
    clientPrimaryAccountId: number,
    childId: number,
    selectTab: number,
    brainFunctionsIds: number[],
    reviewStatus: EvaluationReviewStatus,
  ) => {
    Object.keys(this._assessmentEvaluationModel[selectTab]).forEach(category => {
      Object.keys(this._assessmentEvaluationModel[selectTab][category]).forEach(domain => {
        const evaluations = this._assessmentEvaluationModel[selectTab][category][
          domain
        ].evaluations.map((item: BrainFunctionEvaluation) => {
          if (brainFunctionsIds.includes(item.id)) {
            return {
              ...item,
              evaluation: { ...item.evaluation, reviewStatus },
            };
          }

          return item;
        });

        const markedAsNotYetAchieved =
          this._assessmentEvaluationModel[selectTab][category][domain].markedAsNotYetAchieved;
        const domainDescription =
          this._assessmentEvaluationModel[selectTab][category][domain].domainDescription;

        this._assessmentEvaluationModel[selectTab][category][domain] = new Domain(
          evaluations,
          markedAsNotYetAchieved,
          domainDescription,
        );
      });
    });

    await this.service.reviewBrainFunctions(
      clientPrimaryAccountId,
      childId,
      brainFunctionsIds,
      reviewStatus,
    );
  };

  @action reviewAdditionalCriteria = async (
    clientPrimaryAccountId: number,
    childId: number,
    reviewStatus: EvaluationReviewStatus,
  ) => {
    this._additionalCriteriasEvaluationModel = this._additionalCriteriasEvaluationModel.map(ac => ({
      ...ac,
      reviewStatus,
    }));

    await this.service.reviewAdditionalCriteria(
      clientPrimaryAccountId,
      childId,
      this._additionalCriteriasEvaluationModel.map(ac => ac.clinicalCriterionId),
      reviewStatus,
    );
  };

  @action editChildAge = async (
    clientPrimaryAccountId: number,
    childId: number,
    ageAtLevel: AgeLevelType,
  ) => {
    this._ageLevelEight = {
      ...this._ageLevelEight,
      [ageAtLevel.brainDomain]: ageAtLevel.functionalAgeAtLevel8,
    };
    this._isValidAge = this.isValidChildAge(this._assessmentEvaluationModel, this._ageLevelEight);

    await this.service.editChildAge(clientPrimaryAccountId, childId, ageAtLevel);
  };

  @action markDomainAsNotYetAchieved = async (
    assessmentId: number,
    clientPrimaryAccountId: number,
    childId: number,
    selectTab: number,
    category: string,
    domain: string,
  ) => {
    const currentBrainLevelId = selectTab + 1;

    this._assessmentEvaluationModel[selectTab][category][domain].markedAsNotYetAchieved = true;

    await this.service.markDomainAsNotYetAchieved(
      clientPrimaryAccountId,
      childId,
      domain,
      currentBrainLevelId,
    );

    await this.getEvaluations(assessmentId);
  };

  @action editAdditionalCriteria = async (
    clientPrimaryAccountId: number,
    childId: number,
    clinicalCriterionOptionID: string,
  ) => {
    const additionalCriteria = this._additionalCriteriasEvaluationModel.map(item => {
      const activeOption = item.options.find(
        option => option.clinicalCriterionOptionID === Number(clinicalCriterionOptionID),
      );
      if (activeOption) {
        return {
          ...item,
          option: activeOption,
          historicalClinicalCriterionOptionId:
            item?.historicalClinicalCriterionOptionId ?? item.option.clinicalCriterionOptionID,
        };
      }

      return item;
    });

    this._additionalCriteriasEvaluationModel = additionalCriteria;
    this._isValidCriteria = this.isValidAdditionalCriteria(additionalCriteria);

    const ids = additionalCriteria
      .map(item => {
        if (item.option.clinicalCriterionOptionID) {
          return item.option.clinicalCriterionOptionID;
        }
      })
      .filter(Boolean);

    await this.service.editAdditionalCriteria(clientPrimaryAccountId, childId, ids);
  };

  @action editAbilitiesNote = async (
    assessmentId: string,
    selectTab: number,
    category: string,
    domain: string,
    note: AbilitiesNote,
  ) => {
    const evaluations = this._assessmentEvaluationModel[selectTab][category][
      domain
    ].evaluations.map(item => {
      if (item.id === note.brainFunctionId) {
        return {
          ...item,
          note: note.text,
        };
      }

      return item;
    });

    this._assessmentEvaluationModel[selectTab][category][domain].evaluations = evaluations;

    await this.service.editAbilitiesNote(assessmentId, note);
  };

  @action editAdditionalCriteriaNote = async (
    assessmentId: string,
    note: AdditionalCriteriaNote,
  ) => {
    const additionalCriteria = this._additionalCriteriasEvaluationModel.map(item => {
      if (item.clinicalCriterionId === note.clinicalCriterionId) {
        return {
          ...item,
          note: note.text,
        };
      }

      return item;
    });

    this._additionalCriteriasEvaluationModel = additionalCriteria;

    await this.service.editAdditionalCriteriaNote(assessmentId, note);
  };

  @action getAbilitiesNoteHistory = async (
    childId: number,
    assessmentId: string,
    brainFunctionId: number,
  ) => {
    this._assessmentNotesHistory = await this.service.getAbilitiesNoteHistory(
      childId,
      assessmentId,
      brainFunctionId,
    );
  };

  @action getAdditionalCriteriaNoteHistory = async (
    childId: number,
    assessmentId: string,
    clinicalCriterionId: number,
  ) => {
    this._assessmentNotesHistory = await this.service.getAdditionalCriteriaNoteHistory(
      childId,
      assessmentId,
      clinicalCriterionId,
    );
  };

  @action createNewAssessment = async (clientPrimaryAccountId: number, childId: number) => {
    const response = await this.service.createNewAssessment(clientPrimaryAccountId, childId);

    return response.id;
  };

  @action approveAssessment = async (clientPrimaryAccountId: string, childId: string) => {
    return await this.service.approveAssessment(clientPrimaryAccountId, childId);
  };

  @action submitAssessment = async (clientPrimaryAccountId: string, childId: string) => {
    return await this.service.submitAssessment(clientPrimaryAccountId, childId);
  };

  /** Submit subsequent assessment created by clinician, review all functions and approve */
  @action finalizeAssessment = async (clientPrimaryAccountId: string, childId: string) => {
    return await this.service.finalizeAssessment(clientPrimaryAccountId, childId);
  };

  @action getAssessmentStatus = async (
    clientPrimaryAccountId: string,
    childId: number,
  ): Promise<IGetCurrentStatusResponse> => {
    return await this.service.getAssessmentStatus(clientPrimaryAccountId, childId);
  };

  @action assignAssessmentToClinician = async (
    assessmentId: string,
    clientPrimaryAccountId: string,
    childId: number,
  ) => {
    return await this.service.assignAssessmentToClinician(
      assessmentId,
      clientPrimaryAccountId,
      childId,
    );
  };

  @action getResources = async (brainFunctionId: number) => {
    return await this.service.getResources(brainFunctionId);
  };

  @action isValidAssessmentEvaluation = (data: CategoriesEvaluations[]) => {
    const evaluations: boolean[] = [];

    data.forEach((level: CategoriesEvaluations) => {
      Object.keys(level).forEach((category: string) => {
        const domainsEvaluations = level[category] as DomainsEvaluations;
        Object.keys(domainsEvaluations).forEach((domain: string) => {
          const brainFunctionEvaluation = level[category][domain]
            .evaluations as BrainFunctionEvaluation[];
          brainFunctionEvaluation.forEach((item: BrainFunctionEvaluation) => {
            evaluations.push(Boolean(item.evaluation));
          });
        });
      });
    });

    const result = evaluations.every((elem: boolean) => elem === true);

    return result;
  };

  @action isValidAdditionalCriteria = (data: AdditionalCriteriaType[]) => {
    const options = data.map((item: AdditionalCriteriaType) =>
      Boolean(Object.keys(item.option).length),
    );

    const result = options.every((elem: boolean) => elem === true);

    return result;
  };

  @action isValidChildAge = (tabs: CategoriesEvaluations[], childAge: ChildAgeModel) => {
    const level = tabs[7];

    return Object.keys(level)
      .map(category =>
        Object.keys(level[category]).map(domain => {
          if (level[category][domain].evaluations.length) {
            const evaluations = level[category][domain].evaluations.map(
              item => item.evaluation?.evaluationRating,
            );
            const evaluationRating = evaluations.every(elem => elem === 'NYA');

            return childAge[domain] <= 72 && !evaluationRating;
          }

          return false;
        }),
      )
      .flatMap(v => v)
      .every(item => item === false);
  };
}
