export const evaluationRatingValue = {
  NYA: 0,
  PA: 0.5,
  NA: 0.75,
  A: 1,
};

export const evaluationRatingView = {
  A: {
    text: 'Achieved',
    color: '#16C79C',
    background: '#EAF7F9',
  },
  NA: {
    text: 'Nearly achieved',
    color: '#0C7AE0',
    background: '#E9F4FF',
  },
  PA: {
    text: 'Partially achieved',
    color: '#F8A04E',
    background: '#FFECDB',
  },
  NYA: {
    text: 'Not yet achieved',
    color: '#7D859A',
    background: '#F0F0F0',
  },
};
