export interface AssessmentListItemDTO {
  assessmentId: number;
  submitDate: string;
}

export class AssessmentListItem {
  assessmentId: number;
  submitDate: string;

  constructor(dto: AssessmentListItemDTO) {
    this.update(dto);
  }

  get asJson(): AssessmentListItemDTO {
    return {
      assessmentId: this.assessmentId,
      submitDate: this.submitDate,
    };
  }

  update(newData: Partial<AssessmentListItemDTO>): AssessmentListItemDTO {
    const allData = { ...this.asJson, ...newData };

    Object.assign(this, allData);

    return this.asJson;
  }
}
