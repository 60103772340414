import * as React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

import styles from './EmployeeManagementActive.styles';

interface EmployeeManagementActiveProps
  extends Omit<SvgIconProps, 'classes'>,
    WithStyles<typeof styles> {}

const EmployeeManagementActive: React.FC<EmployeeManagementActiveProps> = ({ classes }) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      classes={{ root: classes.root }}
    >
      <rect width="24" height="24" rx="6" fill="#13A9BA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 5C8.136 5 5 8.136 5 12C5 15.864 8.136 19 12 19C15.864 19 19 15.864 19 12C19 8.136 15.864 5 12 5ZM8.54922 16.396C8.85022 15.766 10.6842 15.15 12.0002 15.15C13.3162 15.15 15.1572 15.766 15.4512 16.396C14.4992 17.152 13.3022 17.6 12.0002 17.6C10.6982 17.6 9.50122 17.152 8.54922 16.396ZM12 13.75C13.022 13.75 15.451 14.163 16.452 15.381C17.166 14.443 17.6 13.274 17.6 12C17.6 8.913 15.087 6.4 12 6.4C8.913 6.4 6.4 8.913 6.4 12C6.4 13.274 6.834 14.443 7.548 15.381C8.549 14.163 10.978 13.75 12 13.75ZM12 7.8C10.642 7.8 9.55 8.892 9.55 10.25C9.55 11.608 10.642 12.7 12 12.7C13.358 12.7 14.45 11.608 14.45 10.25C14.45 8.892 13.358 7.8 12 7.8ZM10.95 10.25C10.95 10.831 11.419 11.3 12 11.3C12.581 11.3 13.05 10.831 13.05 10.25C13.05 9.669 12.581 9.2 12 9.2C11.419 9.2 10.95 9.669 10.95 10.25Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default withStyles(styles)(EmployeeManagementActive);
