import { injectable } from 'inversify';

import { Family, FamilyDTO } from '@shared/models/family';
import BaseService from '@core/services/base';
import Axios, { AxiosInstance } from 'axios';
import HttpClient from '@core/HttpClient';
import container from '@core/di';
import Config from '@core/config';
import { FamilyInfo } from '@shared/models/familyInfo';
import { AssessmentListItem } from '@shared/models/assesmentlistitem';
import { AssessmentItem } from '@shared/models/assesmentitem';
import { LefeQualityReport } from '@shared/models/lifequalityreport';
import { Profile } from '@Client/shared/models/profile';
import { TreatmentPlan } from '@shared/models/treatmentplan';
import { OptionsProgres } from '@shared/models/optionsprogress';
import { PagedResponse } from '@shared/models/pagedresponse';
import { DraftAssessment } from '@shared/models/draftassessment';
import { SortingType } from '@shared/constants/services';
import { ListResponse } from '@shared/types/services';
import { getQueries } from '@shared/utils/common';
import {
  FAMILY_FILTER_ASSIGNED_ALL_ID,
  FAMILY_FILTER_NOT_ASSIGNED_ID,
} from '@shared/constants/family';

export interface GetFamiliesParams {
  Page?: number;
  PageSize?: number;
  CountryId?: number;
  SubscriptionPlanId?: number;
  SearchKeyword?: string;
  DegreeOfNeurologicalFunction?: string;
  AssignedUserAccountSSOId?: string;
  IsAssigned?: boolean;
  'Sort.Field'?: string;
  'Sort.Type'?: SortingType;
}

@injectable()
export default class FamiliesService extends BaseService<FamilyDTO, Family> {
  static diToken = Symbol('families-service');
  private httpClient: AxiosInstance;

  constructor() {
    super({
      domainArea: 'client-primary-accounts',
      Model: Family,
      collection: 'clientprimaryaccount',
    });
    this.httpClient = container.get<HttpClient>(HttpClient.diToken).getInstance();
  }

  async getFamiliesList(params?: GetFamiliesParams) {
    const { data } = await Axios.get<ListResponse<FamilyDTO>>(this.urlPrefix, {
      params: params ?? this.getFamilyListParams(),
    });

    const { items, pageInfo } = data;

    return {
      pageInfo,
      items: items.map(dto => new Family(dto)),
    };
  }

  private getFamilyListParams(): GetFamiliesParams {
    const queries = getQueries();

    const IsAssigned =
      queries.filtering?.clinician === FAMILY_FILTER_NOT_ASSIGNED_ID ? false : undefined;

    const getAssignedUserAccountSSOId = () => {
      if (
        !queries.filtering?.clinician ||
        queries.filtering.clinician === FAMILY_FILTER_NOT_ASSIGNED_ID ||
        queries.filtering.clinician === FAMILY_FILTER_ASSIGNED_ALL_ID
      ) {
        return undefined;
      }

      return queries.filtering.clinician;
    };

    return {
      IsAssigned,
      'Sort.Field': queries.sorting?.field,
      'Sort.Type': queries.sorting?.type,
      Page: queries.pagination?.page,
      PageSize: queries.pagination?.pageSize,
      SearchKeyword: queries.filtering?.searchKeyword || undefined,
      CountryId: queries.filtering?.countryId || undefined,
      SubscriptionPlanId: queries.filtering?.subscriptionPlanId || undefined,
      DegreeOfNeurologicalFunction: queries.filtering?.degreeOfNeurologicalFunction || undefined,
      AssignedUserAccountSSOId: getAssignedUserAccountSSOId(),
    };
  }

  async getFamilyInfo(uId: string) {
    const { data } = await this.httpClient.get<FamilyInfo>(this.getURL(`${uId}`));

    return data;
  }

  assignClinician({ clinicianId, clientId }: { clinicianId: string; clientId: string }) {
    return this.httpClient.put(this.getURL(`${clientId}:assignClinician?userSSOId=${clinicianId}`));
  }

  unassignClinician(clientId: string) {
    return this.httpClient.put(this.getURL(`${clientId}:unassignClinician`));
  }

  async getAssessmentsList(clientPrimaryAccountId: string, childId: string) {
    const {
      data: { items },
    } = await this.httpClient.get<PagedResponse<AssessmentListItem>>(
      this.getURL(`${clientPrimaryAccountId}/children/${childId}/assessments/approved`),
    );

    return items as AssessmentListItem[];
  }

  async getAssessmentsHistory(
    clientPrimaryAccountId: string,
    childId: number,
    page: number,
    pageSize: number,
  ) {
    const { data } = await this.httpClient.get(
      this.getURL(
        `${clientPrimaryAccountId}/children/${childId}/assessments/approved?Page=${page}&PageSize=${pageSize}`,
      ),
    );

    return data;
  }

  async getDraftAssessment(clientPrimaryAccountId, childId) {
    const { data } = await this.httpClient.get<{ items: DraftAssessment[] }>(
      this.getURL(`${clientPrimaryAccountId}/children/${childId}/assessments/draft`),
    );

    return data.items.map(item => new DraftAssessment(item));
  }

  async getRemainingAssessments(clientPrimaryAccountId: string) {
    const config = container.get<Config>(Config.diToken);
    const { apiURL } = config.get().client;
    const { data } = await this.httpClient({
      method: 'get',
      baseURL: `${apiURL.origin}${apiURL.pathname}`,
      url: this.getURL(`${clientPrimaryAccountId}/assessments/remaining-assessments`),
    });

    return data;
  }

  async addExtraAssessment(clientPrimaryAccountId: string) {
    const config = container.get<Config>(Config.diToken);
    const { apiURL } = config.get().client;
    const { data } = await this.httpClient({
      method: 'post',
      baseURL: `${apiURL.origin}${apiURL.pathname}`,
      url: this.getURL(`${clientPrimaryAccountId}/extra-assessments`),
    });

    return data;
  }

  async getAssessment(clientPrimaryAccountId: string, childId: string, assessmentId: string) {
    const { data } = await this.httpClient.get<AssessmentItem>(
      this.getURL(`${clientPrimaryAccountId}/children/${childId}/assessments/${assessmentId}`),
    );

    return data;
  }

  async getTreatmentPlan(clientPrimaryAccountId: string, childId: string, planId: number) {
    const { data } = await this.httpClient.get<TreatmentPlan[]>(
      this.getURL(`${clientPrimaryAccountId}/children/${childId}/treatment-plans/${planId}`),
    );

    return data;
  }

  async getCurrentTreatmentPlan(clientPrimaryAccountId: string, childId: string) {
    const { data } = await this.httpClient.get<TreatmentPlan[]>(
      this.getURL(`${clientPrimaryAccountId}/children/${childId}/treatment-plans/current`),
    );

    return data;
  }

  async getOptionsProgress(
    clientPrimaryAccountId: string,
    childId: string,
    treatmentOptionId: number,
  ) {
    // https://api.admin.fhc-dev.net/api/v1/client-primary-accounts/1114/children/1114/options-progress/131

    const { data } = await this.httpClient.get<OptionsProgres[]>(
      this.getURL(
        `${clientPrimaryAccountId}/children/${childId}/options-progress/${treatmentOptionId}`,
      ),
    );

    return data;
  }

  async getQalityReport(clientPrimaryAccountId: string, id: string) {
    const { data } = await this.httpClient.get<LefeQualityReport>(
      this.getURL(`${clientPrimaryAccountId}/life-quality-report/${id}`),
    );

    return data;
  }

  async current() {
    const { data } = await this.httpClient.get<Profile>(this.getURL('current'));

    return data;
  }

  callCompletedPlanReview(clientPrimaryAccountId: string) {
    return this.httpClient.put(
      this.getURL(`${clientPrimaryAccountId}:complete-initial-treatment-plan-review`),
    );
  }
}
