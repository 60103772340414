import { observable, computed, action } from 'mobx';
import { injectable, unmanaged } from 'inversify';

import { ListRequestParams, PageInfo } from '@shared/types/services';
import { getQueries, setQueries } from '@shared/utils/common';
import { Id } from '@shared/types/common';

@injectable()
export default class BaseStore<
  T extends InstanceType<any>,
  K extends InstanceType<any>,
  U extends InstanceType<any>,
  P = ListRequestParams,
> {
  static diToken = Symbol('base-store');
  protected service: U;
  @observable protected _list: Array<K> = [];
  @observable protected _pageInfo: PageInfo;
  @observable loading: { [key: string]: boolean } = {
    list: false,
    item: false,
  };

  constructor(@unmanaged() { service }: { service: U }) {
    this.service = service;
  }

  @computed get pageInfo() {
    return this._pageInfo;
  }

  @computed get loadingList() {
    return this.loading.list;
  }

  @computed get list() {
    return this._list;
  }

  @action resetList = () => {
    this._list = [];
  };

  // updateLoading(property: string, value: boolean) {
  //   this.loading = {
  //     ...this.loading,
  //     [property]: value
  //   };
  // }

  @action getList = async (params?: P): Promise<{ items: Array<K>; pageInfo: PageInfo } | void> => {
    this.loading.list = true;

    const queries: ListRequestParams = getQueries();

    try {
      const { items, pageInfo } = await this.service.getList(params || queries);

      this._list = items;
      this._pageInfo = pageInfo;

      // this.loading.list = false;

      return {
        items,
        pageInfo,
      };
    } catch (err) {
    } finally {
      this.loading.list = false;
    }
  };

  create = async (data: T, shouldUpdateList = true, ...args: Array<any>) => {
    const response = await this.service.create(data, ...args);

    if (shouldUpdateList) {
      this.getList();
    }

    return response;
  };

  get = async (id: Id, ...args: Array<any>): Promise<K> => {
    this.loading.item = true;

    try {
      const item = await this.service.get(id, ...args);
      this.loading.item = false;

      return item;
    } catch (err) {
      this.loading.item = false;
      throw err;
    }
  };

  edit = async (id: T['id'], data: Partial<T>, shouldUpdateList = true, ...args: Array<any>) => {
    await this.service.edit(id, data, ...args);

    if (shouldUpdateList) {
      this.getList();
    }
  };

  delete = async (id: Id) => {
    await this.service.delete(id);

    if (this._pageInfo) {
      const { hasNextPage, hasPreviousPage } = this._pageInfo;
      const hasOnlyOnePage = !hasNextPage && !hasPreviousPage;

      if (hasOnlyOnePage) {
        this.getList();

        return;
      }

      const isLastPage = !hasNextPage;
      const queries: ListRequestParams = getQueries();
      const { pagination } = queries;

      if (isLastPage && pagination) {
        const { totalCount } = this._pageInfo;
        const page = Number(pagination.page);
        const pageSize = Number(pagination.pageSize);
        const itemsAmountLeftOnLastPage = totalCount - pageSize * page;

        if (itemsAmountLeftOnLastPage === 1) {
          const previousPage = page - 1;
          const mergedQueries: ListRequestParams = {
            ...queries,
            pagination: {
              ...queries.pagination,
              page: previousPage,
            },
          };

          setQueries(mergedQueries);
        }
      }
    }

    this.getList();
  };
}
