import * as React from 'react';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import Badge from '@material-ui/core/Badge';
import { List, withStyles, WithStyles } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { getTestAttr } from '@shared/utils/common';
import styles from './NavigationMenu.styles';
import { Item, MENU_LINKS } from './NavigationMenu.constants';
import container from '@core/di';
import { AuthStore } from '@shared/stores';

export interface NavigationMenuProps extends WithStyles<typeof styles> {
  notAssignedAssessmentCount: number;
  totalNotApprovedEntitiesCount: number;
}

class NavigationMenu extends React.Component<NavigationMenuProps> {
  private authStore = container.get<AuthStore>(AuthStore.diToken);

  render() {
    const { classes } = this.props;
    const {
      userProfile: { role },
    } = this.authStore;
    const { totalNotApprovedEntitiesCount, notAssignedAssessmentCount } = this.props;

    const valuesCount = [totalNotApprovedEntitiesCount, notAssignedAssessmentCount];
    const idsSection = [1, 2];

    const menuRoleItems: Array<Item> = MENU_LINKS.filter(({ roles }) => roles.includes(role));

    return (
      <>
        <List>
          <div className={cx(classes.content, classes.flex, classes.flexColumn)}>
            {menuRoleItems.map(item => (
              <ListItem button disableRipple className={classes.listItem} key={item.id}>
                <NavLink
                  {...getTestAttr(item.name, 'nav-link', 'btn')}
                  to={item.route}
                  className={cx(classes.navLink, classes.fullWidth, {
                    [classes.activeRouteClass]:
                      item.route.split('/')[1] === location.pathname.split('/')[1],
                  })}
                >
                  <ListItemIcon
                    className={cx(
                      classes.listItemIcon,
                      classes.alignItemsCenter,
                      classes.justifyContentCenter,
                    )}
                  >
                    {location.pathname === item.route ? item.activeIcon : item.icon}
                  </ListItemIcon>
                  <Badge
                    badgeContent={
                      <span>
                        +
                        {(item.id === 1 && totalNotApprovedEntitiesCount) ||
                          (item.id === 2 && notAssignedAssessmentCount)}
                      </span>
                    }
                    invisible={!idsSection.includes(item.id) || !valuesCount[item.id - 1]}
                    color="primary"
                  >
                    <ListItemText
                      primary={item.name}
                      classes={{
                        primary: cx(classes.listItemTextRoot, classes.ellipsisText),
                        root: classes.listItemText,
                      }}
                    />
                  </Badge>
                </NavLink>
              </ListItem>
            ))}
          </div>
        </List>
      </>
    );
  }
}

export default withStyles(styles)(NavigationMenu);
