import * as React from 'react';
import { withStyles, WithStyles } from '@material-ui/core';
import {
  RouteComponentProps,
  Switch,
  Route,
  RouteProps,
  Redirect,
} from 'react-router-dom';
import { observer } from 'mobx-react';
import Lazyload from '@shared/components/LazyLoad';

import styles from './Public.styles';
import SHARED_ROUTES from '@shared/constants/routes';

const Login = React.lazy(() => import('./submodules/Login'));
const Singin = React.lazy(() => import('./submodules/Singin'));

export interface PublicProps
  extends WithStyles<typeof styles>,
    RouteComponentProps {
  routes?: Array<RouteProps>;
}

@observer
class Public extends React.Component<PublicProps> {
  get routes(): Array<RouteProps> {
    const { login, singingOidc } = SHARED_ROUTES.public;

    return [
      { path: singingOidc, component: Singin },
      { path: login, component: Login },
    ];
  }

  private get modules() {
    return (
      <Lazyload>
        <Switch>
          {this.props.routes?.map((routeProps) => (
            <Route key={String(routeProps.path)} {...routeProps} />
          ))}

          {this.routes.map((routeProps) => (
            <Route key={String(routeProps.path)} {...routeProps} />
          ))}
          <Redirect to={SHARED_ROUTES.public.login} />
        </Switch>
      </Lazyload>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.modules}>{this.modules}</div>
      </div>
    );
  }
}

export default withStyles(styles)(Public);
