import * as React from 'react';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import Fade from '@material-ui/core/Fade';
import Dialog from '@material-ui/core/Dialog';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import cx from 'classnames';

import Button from '@shared/components/Button';
import ErrorIcon from '@shared/icons/Error';
import CheckMarkIcon from '@shared/icons/CheckMark';
import EnvelopeIcon from '@shared/icons/Envelope';

import styles from './Notification.styles';

export enum NotificationType {
  info,
  error,
  success,
  email,
}

type ComponentType = 'snackbar' | 'dialog';

export interface NotificationProps extends WithStyles<typeof styles> {
  autoHideDuration?: number | null;
  className?: string;
  component?: ComponentType;
  autoClose?: boolean;
}

interface NotificationState {
  message: React.ReactNode;
  type: NotificationType;
  notificationProps: Partial<NotificationProps>;
  open: boolean;
}

const defaultProps: Partial<NotificationProps> = {
  autoClose: true,
  autoHideDuration: 5000,
  className: '',
  component: 'snackbar',
};

let showNotificationFn;
let hideNotificationFn;
let isOpenNotificationFn;

export function showNotification(
  message: React.ReactNode,
  type: NotificationType = NotificationType.info,
  notificationProps?: Partial<NotificationProps>,
) {
  showNotificationFn(message, type, notificationProps);
}

export function hideNotification() {
  hideNotificationFn();
}

export function isOpenNotification(): boolean {
  return isOpenNotificationFn();
}

const icons = {
  [NotificationType.info]: null,
  [NotificationType.error]: <ErrorIcon />,
  [NotificationType.success]: <CheckMarkIcon />,
  [NotificationType.email]: <EnvelopeIcon />,
};

class Notification extends React.Component<NotificationProps, NotificationState> {
  readonly state = {
    message: '',
    type: NotificationType.info,
    notificationProps: defaultProps,
    open: false,
  };

  componentDidMount() {
    showNotificationFn = this.open;
    hideNotificationFn = this.close;
    isOpenNotificationFn = this.isOpenNotification;
  }

  private isOpenNotification = () => this.state.open;

  private open = (
    message: React.ReactNode | any,
    type: NotificationType = NotificationType.success,
    notificationProps?: NotificationProps,
  ) => {
    const { classes } = this.props;
    const componentTypeClass: { [key in ComponentType]: string } = {
      snackbar: classes.snackbarContentWrapper,
      dialog: classes.dialogContentWrapper,
    };

    if (message?.response) {
      try {
        message = Object.keys(message?.response?.data?.errors)
          .map(key => message?.response?.data?.errors[key])
          .join('.');
      } catch {
        message = 'Error';
      }
    }

    message = (
      <div
        className={cx(
          classes.contentWrapper,
          notificationProps?.component ? componentTypeClass[notificationProps.component] : '',
        )}
      >
        {icons[type]}
        {message}
      </div>
    );

    this.setState({
      type,
      message,
      notificationProps: {
        ...defaultProps,
        ...notificationProps,
      },
      open: true,
    });
  };

  private close = () => {
    this.setState({ notificationProps: defaultProps, open: false });
  };

  render() {
    const { classes } = this.props;
    const { message, type, notificationProps, open } = this.state;
    const { autoHideDuration, component, autoClose } = notificationProps;

    return (
      <>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          autoHideDuration={autoHideDuration}
          open={open && component === 'snackbar'}
          TransitionComponent={Fade}
          onClose={autoClose ? this.close : undefined}
        >
          <SnackbarContent
            classes={{
              root: cx(classes.root, classes[type]),
            }}
            message={message}
            action={
              <span className={classes.closeBtn} onClick={this.close}>
                Close
              </span>
            }
          />
        </Snackbar>
        <Dialog
          classes={{ paper: cx(classes.root, classes.dialog) }}
          open={open && component === 'dialog'}
        >
          {message}
          <Button text="Ok" onClick={this.close} />
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(Notification);
