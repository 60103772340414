export type Country = { countryName: string; id: number };
export type SubscriptionPlan = { name: string; id: number };

export interface ActivateSubscriptionDTO {
  subscriptionTypeId: number;
  paymentProviderPaymentMethodId?: string;
  billingPeriod: string;
}

export class ActivateSubscription implements ActivateSubscriptionDTO {
  subscriptionTypeId: number;
  paymentProviderPaymentMethodId?: string;
  billingPeriod: string;

  constructor(dto: ActivateSubscriptionDTO) {
    this.update(dto);
  }

  get asJson(): ActivateSubscriptionDTO {
    return {
      subscriptionTypeId: this.subscriptionTypeId,
      paymentProviderPaymentMethodId: this.paymentProviderPaymentMethodId,
      billingPeriod: this.billingPeriod,
    };
  }

  update(newData: Partial<ActivateSubscriptionDTO>): ActivateSubscriptionDTO {
    const allData = { ...this.asJson, ...newData };

    Object.assign(this, allData);

    return this.asJson;
  }
}
