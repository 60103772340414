import * as React from 'react';
import { withStyles, WithStyles } from '@material-ui/core';
import { RouteComponentProps, RouteProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import styles from './Public.styles';
import Public from '@shared/modules/Public';
import ROUTES from '@Admin/shared/constants/routes';
const Signout = React.lazy(() => import('../Private/submodules/Signout'));

const Loading = React.lazy(() => import('@shared/components/Loading'));

export interface PublicProps
  extends WithStyles<typeof styles>,
  RouteComponentProps { }

@observer
class PublicModule extends React.Component<PublicProps> {
  get routes(): Array<RouteProps> {
    const { notFound, signoutOidc } = ROUTES.public;

    return [{ path: notFound, component: Loading }, { path: signoutOidc, component:Signout  }];
  }

  render() {
    return <Public routes={this.routes} {...this.props} />;
  }
}

export default withStyles(styles)(PublicModule);
