import { injectable } from 'inversify';
import container from '@core/di';
import BaseStore from '@core/stores/base';
import { Profile, ProfileDTO } from '@shared/models/profile';
import { action, computed, observable } from 'mobx';
import ProfileService from '@shared/services/profile';

@injectable()
export default class ProfileStore extends BaseStore<ProfileDTO, Profile, ProfileService> {
  constructor() {
    super({ service: container.get<ProfileService>(ProfileService.diToken) });
  }

  static diToken = Symbol('profile-store');

  @observable private _profile: Profile;

  @computed get profile(): Profile {
    return this._profile;
  }

  set profile(profile: Profile) {
    this._profile = profile;
  }

  @action current = async () => {
    const profile = await this.service.getProfile();
    this._profile = profile;

    return profile;
  };

  @action update = async data => {
    return this.service.update(data);
  };

  @action removeAvatar = async () => {
    return this.service.removeAvatar();
  };

  @action removeUser = async (ssoId: string) => {
    return this.service.removeUser(ssoId);
  };

  @action uploadAvatar = async formData => {
    return this.service.uploadAvatar(formData);
  };
}
