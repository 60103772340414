import * as React from 'react';
import { withStyles, WithStyles } from '@material-ui/core';
import MuiButton, { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from './Button.styles';

type Classes = WithStyles<typeof styles> & Pick<MuiButtonProps, 'classes'>;

export interface ButtonProps extends Omit<MuiButtonProps, 'classes'>, Partial<Classes> {
  text?: React.ReactNode;
  loading?: boolean;
  error?: boolean;
  component?: React.ComponentType<any>;
  componentProps?: any;
}

const Button: React.FC<ButtonProps> = ({
  text,
  loading,
  error,
  children,
  component: Component,
  componentProps,
  ...otherProps
}) => {
  const baseProps: MuiButtonProps = {
    variant: 'contained',
    size: 'large',
    disabled: loading,
    color: 'primary',
    style: error ? { backgroundColor: '#DC5A3F', color: 'white' } : {},
    ...otherProps,
  };

  const spinner = loading ? (
    <CircularProgress size={15} style={{ marginLeft: 8, color: '#fff' }} />
  ) : null;

  const content = (
    <MuiButton {...baseProps}>
      {text || children} {spinner}
    </MuiButton>
  );

  if (Component) {
    return <Component {...componentProps}>{content}</Component>;
  }

  return content;
};

export default withStyles(styles)(Button);
