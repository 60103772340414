import { observable } from 'mobx';

export enum ROLES {
  Admin = 'Administrator',
  SeniorClinician = 'SeniorClinician',
  MasterClinician = 'MasterClinician',
  Clinician = 'Clinician',
  SuperAdministrator = 'SuperAdministrator',
  ClientPrimaryAccount = 'ClientPrimaryAccount',
}

export interface EmployeeDTO {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
}
export class Employee {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  @observable role: string;

  constructor(dto: EmployeeDTO) {
    this.update(dto);
  }

  get asJson(): EmployeeDTO {
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      role: this.role,
    };
  }

  update(newData: Partial<EmployeeDTO>): EmployeeDTO {
    const allData = { ...this.asJson, ...newData };

    Object.assign(this, allData);

    return this.asJson;
  }
}
