import { injectable } from 'inversify';

import BaseService from '@core/services/base';
import { AxiosInstance } from 'axios';
import HttpClient from '@core/HttpClient';
import container from '@core/di';
import { Clinician, ClinicianDTO } from '@shared/models/clinician';

@injectable()
export default class ClinicianService extends BaseService<ClinicianDTO, Clinician> {
  static diToken = Symbol('clinician');
  private httpClient: AxiosInstance;

  constructor() {
    super({ domainArea: 'clinician', Model: Clinician });
    this.httpClient = container.get<HttpClient>(HttpClient.diToken).getInstance();
  }

  async getFirstAssignedAssessments(page, pageSize) {
    const { data } = await this.httpClient.get(
      this.getURL(`current/first-assigned-assessments?Page=${page}&PageSize=${pageSize}`),
    );

    return data;
  }

  async getInitialPlanReview(page: number, pageSize: number) {
    const { data } = await this.httpClient.get(
      this.getURL(`current/initial-treatment-plans?Page=${page}&PageSize=${pageSize}`),
    );

    return data;
  }

  async getAssignedAssessmentsInfo() {
    const { data } = await this.httpClient.get(this.getURL('assign-assessments-info'));

    return data;
  }

  async getSubsiquentAssignedAssessments(page: number, pageSize: number) {
    const { data } = await this.httpClient.get(
      this.getURL(`current/subsequent-assigned-assessments?Page=${page}&PageSize=${pageSize}`),
    );

    return data;
  }

  async getAssessmentsTreatmentPlansInfo() {
    const { data } = await this.httpClient.get(
      this.getURL('current/assigned-assessments-treatment-plans-info'),
    );

    return data;
  }
}
