import { createStyles, Theme } from '@material-ui/core';
import { scrollStyles } from '@core/theme';

export default ({ typography, palette: { colors }, spacing }: Theme) =>
  createStyles({
    '@global': {
      '*': {
        margin: 0,
        padding: 0,
        border: 0,
        fontWeight: 400,
      },
      body: {
        height: '100%',
        fontFamily: typography.fontFamily,
        color: colors.primary,
        overflow: 'hidden',
        backgroundColor: 'white',

        '& strong': {
          color: 'black',
          cursor: 'pointer',
          fontWeight: 500,
        },

        '& *': scrollStyles,
      },
      html: {
        height: '100%',
      },
      '#root': {
        height: '100%',
      },
      a: {
        display: 'inline-flex',
        color: 'inherit',
        outline: 'none',
        textDecoration: 'none',
        cursor: 'pointer',
      },
      span: {
        lineHeight: 1.2,
      },
      'input::-ms-clear': {
        display: 'none',
      },
    },
    root: {
      height: '100vh',
    },
    restrictedMessage: {
      textAlign: 'center',
    },
    restrictedMessageTitle: {
      fontSize: 24,
      margin: spacing(2, 0),
    },
  });
