export type Country = { countryName: string; id: number };
export type SubscriptionPlan = { name: string; id: number };

export enum SubscriptionStatus {
  Active = 'Active',
  Cancelled = 'Cancelled',
  Expired = 'Expired',
}

export type Subscription = {
  plan: SubscriptionPlan;
  trial: boolean;
  expirationDate: string;
  status: SubscriptionStatus;
};

export interface ProfileDTO {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  country: Country;
  familyRole: string;
  subscription: Subscription;
  profileImageUrl: string;
  timeZone: string;
}

export class Profile implements ProfileDTO {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  country: Country;
  familyRole: string;
  subscription: Subscription;
  profileImageUrl: string;
  timeZone: string;

  constructor(dto: ProfileDTO) {
    this.update(dto);
  }

  get asJson(): ProfileDTO {
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      phoneNumber: this.phoneNumber,
      country: this.country,
      familyRole: this.familyRole,
      subscription: this.subscription,
      profileImageUrl: this.profileImageUrl,
      timeZone: this.timeZone,
    };
  }

  update(newData: Partial<ProfileDTO>): ProfileDTO {
    const allData = { ...this.asJson, ...newData };

    Object.assign(this, allData);

    return this.asJson;
  }
}
