import * as React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

import styles from './Assessment.styles';

interface AssessmentProps extends Omit<SvgIconProps, 'classes'>, WithStyles<typeof styles> {}

const Assessment: React.FC<AssessmentProps> = ({ classes }) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      classes={{ root: classes.root }}
    >
      <rect width="24" height="24" rx="6" fill="#F7F7FA" />
      <path
        d="M19 9.33333C19 10.0667 18.4273 10.6667 17.7273 10.6667C17.6127 10.6667 17.5045 10.6533 17.4027 10.62L15.1373 12.9867C15.1691 13.0933 15.1818 13.2133 15.1818 13.3333C15.1818 14.0667 14.6091 14.6667 13.9091 14.6667C13.2091 14.6667 12.6364 14.0667 12.6364 13.3333C12.6364 13.2133 12.6491 13.0933 12.6809 12.9867L11.0582 11.2867C10.9564 11.32 10.8418 11.3333 10.7273 11.3333C10.6127 11.3333 10.4982 11.32 10.3964 11.2867L7.50091 14.3267C7.53273 14.4333 7.54545 14.5467 7.54545 14.6667C7.54545 15.4 6.97273 16 6.27273 16C5.57273 16 5 15.4 5 14.6667C5 13.9333 5.57273 13.3333 6.27273 13.3333C6.38727 13.3333 6.49545 13.3467 6.59727 13.38L9.49909 10.3467C9.46727 10.24 9.45455 10.12 9.45455 10C9.45455 9.26667 10.0273 8.66667 10.7273 8.66667C11.4273 8.66667 12 9.26667 12 10C12 10.12 11.9873 10.24 11.9555 10.3467L13.5782 12.0467C13.68 12.0133 13.7945 12 13.9091 12C14.0236 12 14.1382 12.0133 14.24 12.0467L16.4991 9.67333C16.4673 9.56667 16.4545 9.45333 16.4545 9.33333C16.4545 8.6 17.0273 8 17.7273 8C18.4273 8 19 8.6 19 9.33333Z"
        fill="#13A9BA"
      />
    </SvgIcon>
  );
};

export default withStyles(styles)(Assessment);
