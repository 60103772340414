import { injectable } from 'inversify';
import container from '@core/di';
import BaseStore from '@core/stores/base';
import { action } from 'mobx';
import AssessmentsService, { GetAssessmentsListParams } from '@shared/services/assessments';
import { Assessment, AssessmentDTO } from '@shared/models/assessment';
import { ASSESSMENT_SUBSCRIPTIONS_NUMBERS } from '@shared/constants/assessments';

@injectable()
export default class AssessmentsStore extends BaseStore<
  AssessmentDTO,
  Assessment,
  AssessmentsService,
  GetAssessmentsListParams
> {
  static diToken = Symbol('assessments-store');

  constructor() {
    super({
      service: container.get<AssessmentsService>(AssessmentsService.diToken),
    });

    this.loading = {
      list: true,
      item: false,
    };
  }

  @action getList = async (params?: GetAssessmentsListParams) => {
    this.loading.list = true;

    try {
      const { items, pageInfo } = await this.service.getAssessmentsList(params);

      this._list = items;
      this._pageInfo = pageInfo;

      return {
        items,
        pageInfo,
      };
    } finally {
      this.loading.list = false;
    }
  };

  @action getFamilyInfo = async (uId: string) => {
    return await this.service.getFamilyInfo(uId);
  };

  @action getMatrix = async () => {
    return await this.service.getMatrix();
  };

  @action videoThumbnails = async (brainFunctionId: number) => {
    return await this.service.videoThumbnails(brainFunctionId);
  };

  @action renameClientVideo = async (params: {
    brainFunctionId: number;
    subscriptionPlanId: ASSESSMENT_SUBSCRIPTIONS_NUMBERS;
    title: string;
  }) => {
    return await this.service.renameClientVideo(
      params.brainFunctionId,
      params.subscriptionPlanId,
      params.title,
    );
  };

  @action deleteClientVideo = async (params: {
    brainFunctionId: number;
    subscriptionPlanId: ASSESSMENT_SUBSCRIPTIONS_NUMBERS;
  }) => {
    return await this.service.deleteClientVideo(params.brainFunctionId, params.subscriptionPlanId);
  };

  @action uploadClientVideo = (params: {
    brainFunctionId: number;
    subscriptionPlanId: ASSESSMENT_SUBSCRIPTIONS_NUMBERS;
    formData: FormData;
    setProgress: (value: number) => void;
  }) => {
    return this.service.uploadClientVideo(
      params.brainFunctionId,
      params.subscriptionPlanId,
      params.formData,
      params.setProgress,
    );
  };

  @action updateTreatmentPlanModel = async (
    formData: FormData,
    setProgress: (value: number) => void,
  ) => {
    return this.service.updateTreatmentPlanModel(formData, setProgress);
  };

  @action cancelUpload = () => {
    return this.service.cancelUpload();
  };

  @action getAssessmentList = pagination => {
    return this.service.getAssessmentList(pagination);
  };

  @action getClinicianVideo = async (brainFunctionId: number) => {
    try {
      const response = await this.service.getClinicianVideo(brainFunctionId);

      return [response];
    } catch (error) {
      const status = error.response?.status;
      if (status === 404) {
        return [];
      }
    }
  };

  @action renameClinicianVideo = async (params: {
    brainFunctionId: number;
    subscriptionPlanId: ASSESSMENT_SUBSCRIPTIONS_NUMBERS;
    title: string;
  }) => {
    return await this.service.renameClinicianVideo(params.brainFunctionId, params.title);
  };

  @action deleteClinicianVideo = async (params: {
    brainFunctionId: number;
    subscriptionPlanId: ASSESSMENT_SUBSCRIPTIONS_NUMBERS;
  }) => {
    return await this.service.deleteClinicianVideo(params.brainFunctionId);
  };

  @action uploadClinicianVideo = (params: {
    brainFunctionId: number;
    subscriptionPlanId: ASSESSMENT_SUBSCRIPTIONS_NUMBERS;
    formData: FormData;
    setProgress: (value: number) => void;
  }) => {
    return this.service.uploadClinicianVideo(
      params.brainFunctionId,
      params.formData,
      params.setProgress,
    );
  };
}
