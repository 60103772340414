import { injectable } from 'inversify';

import { getEnvironment } from '@shared/utils/env';
import config from './config.json';

type InitialConfig = typeof config;

interface AppConfig {
  apiURL: {
    origin: string;
    pathname: string;
  };
  apiSSO: {
    origin: string;
    pathname: string;
  };
  domain?: string;
  stripePublicKey?: string;
  uploadFileSizeLimit?: number;
  logsPanelToken?: string;
}

interface CommonAppConfig {
  version: string;
}

interface ConfigType {
  admin: AppConfig;
  client: AppConfig;
  common: CommonAppConfig;
}

@injectable()
export default class Config {
  static diToken = Symbol('config');

  private config: ConfigType;

  initialize() {
    this.set();
  }

  get() {
    return this.config;
  }

  private set() {
    const { admin, client, common } = config;
    const env = getEnvironment();

    const getAppConfig = (source: InitialConfig['admin'] | InitialConfig['client']): AppConfig => {
      return env ? source[env] : source.Local;
    };

    this.config = {
      common,
      admin: getAppConfig(admin),
      client: getAppConfig(client),
    };
  }
}
