export const FAMILY_FIELDS = {
  childName: 'childName',
  country: 'country',
  created: 'created',
  CreationDate: 'CreationDate',
  Submitted: 'Submitted',
  deleted: 'deleted',
  email: 'email',
  firstName: 'firstName',
  id: 'id',
  lastName: 'lastName',
  subscriptionPlan: 'subscriptionPlan',
  uId: 'uId',
  degreeOfNeurologicalFunction: 'degreeOfNeurologicalFunction',
  assignedUserFirstName: 'assignedUserFirstName',
  assignedUserLastName: 'assignedUserLastName',
};

export const FAMILY_FILTER_ASSIGNED_ALL_ID = '';
export const FAMILY_FILTER_NOT_ASSIGNED_ID = '0';
