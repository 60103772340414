import { injectable } from 'inversify';

import { getEnvironment } from '@shared/utils/env';
import { UserManager } from 'oidc-client';
import config from './userManagerConfig.json';

type ConfigType = 'admin' | 'client';

@injectable()
export default class AuthManager {
  static diToken = Symbol('authManager');
  private _config;

  private userManager: UserManager;

  initialize(configType: ConfigType) {
    this.set(configType);
  }

  get() {
    return this.userManager;
  }

  config() {
    return this._config;
  }

  private set(configType) {
    const envConfig = config[configType];
    const env = getEnvironment();
    const userManagerConfig = env ? envConfig[env] : envConfig.Local;
    this._config = userManagerConfig;
    this.userManager = new UserManager(userManagerConfig);
  }
}
