export type Price = {
  price: number;
  billingPeriod: string;
};

export interface SubscriptionPlanDTO {
  id: number;
  name: string;
  type: string;
  trialDuration?: number;
  descriptions: string[];
  prices: Price[];
}

export class SubscriptionPlan implements SubscriptionPlanDTO {
  id: number;
  name: string;
  type: string;
  trialDuration?: number;
  descriptions: string[];
  prices: Price[];

  constructor(dto: SubscriptionPlanDTO) {
    this.update(dto);
  }

  get asJson(): SubscriptionPlanDTO {
    return {
      id: this.id,
      name: this.name,
      type: this.type,
      trialDuration: this.trialDuration,
      descriptions: this.descriptions,
      prices: this.prices,
    };
  }

  update(newData: Partial<SubscriptionPlanDTO>): SubscriptionPlanDTO {
    const allData = { ...this.asJson, ...newData };

    Object.assign(this, allData);

    return this.asJson;
  }
}
