import { injectable } from 'inversify';
import BaseService from '@core/services/base';
import { AxiosInstance } from 'axios';
import HttpClient from '@core/HttpClient';
import container from '@core/di';
import { TreatmentPlans, TreatmentPlansDTO } from '@shared/models/treatmentplans';
import Config from '@core/config';
import { TreatmentPlanMetadataDto } from '@shared/models/treatment-plan-metadata';

@injectable()
export default class TreatmentPlansService extends BaseService<TreatmentPlansDTO, TreatmentPlans> {
  static diToken = Symbol('treatment-plans');
  private httpClient: AxiosInstance;

  constructor() {
    super({ domainArea: 'treatment-plans', Model: TreatmentPlans });
    this.httpClient = container.get<HttpClient>(HttpClient.diToken).getInstance();
  }

  getTreatmentPlanMetadata = async (
    childId: string | number,
  ): Promise<TreatmentPlanMetadataDto> => {
    const config = container.get<Config>(Config.diToken);
    const { apiURL } = config.get().client;

    const { data } = await this.httpClient({
      method: 'get',
      baseURL: `${apiURL.origin}${apiURL.pathname}`,
      url: `/child/${childId}/treatment-plans/current/metadata`,
    });

    return data;
  };

  async getNotApprovedTreatmentPlans(page: string, pageSize: string) {
    const config = container.get<Config>(Config.diToken);
    const { apiURL } = config.get().client;
    const { data } = await this.httpClient({
      method: 'get',
      baseURL: `${apiURL.origin}${apiURL.pathname}`,
      url: this.getURL(`not-approved?Page=${page}&PageSize=${pageSize}`),
    });

    return data;
  }

  async GetApprovedTreatmentPlans(page: string, pageSize: string, childId: string) {
    const config = container.get<Config>(Config.diToken);
    const { apiURL } = config.get().client;
    const { data } = await this.httpClient(
      `${apiURL.origin}${apiURL.pathname}/child/${childId}/treatment-plans/approved`,
    );

    return data;
  }

  async ApproveTreatmentPlan(childId: string) {
    const config = container.get<Config>(Config.diToken);
    const { apiURL } = config.get().client;
    const { data } = await this.httpClient({
      method: 'put',
      baseURL: `${apiURL.origin}${apiURL.pathname}`,
      url: `child/${childId}/treatment-plans/current/approve`,
    });

    return data;
  }

  async RejectTreatmentPlan(childId: string, rejectiontNote: string) {
    const config = container.get<Config>(Config.diToken);
    const { apiURL } = config.get().client;
    const { data } = await this.httpClient.put(
      `${apiURL.origin}${apiURL.pathname}/child/${childId}/treatment-plans/current/reject`,
      { rejectNote: rejectiontNote },
    );

    return data;
  }

  async RegenerateTreatmentPlan(childId: string) {
    const config = container.get<Config>(Config.diToken);
    const { apiURL } = config.get().client;
    const { data } = await this.httpClient.put(
      `${apiURL.origin}${apiURL.pathname}/child/${childId}/treatment-plans/regenerate`,
    );

    return data;
  }

  async GetTreatmentOptions(childId: string) {
    const config = container.get<Config>(Config.diToken);
    const { apiURL } = config.get().client;
    const { data } = await this.httpClient.get(
      `${apiURL.origin}${apiURL.pathname}/child/${childId}/treatment-plans/current/treatment-options`,
    );

    return data;
  }

  async GetRejectedTreatmentPlans(assessmentId: string) {
    const config = container.get<Config>(Config.diToken);
    const { apiURL } = config.get().client;
    const { data } = await this.httpClient.get(
      `${apiURL.origin}${apiURL.pathname}/assessments/${assessmentId}/treatment-plans`,
    );

    return data;
  }

  async GetTreatmentPlanOptions(treatmentPlanId: string) {
    const config = container.get<Config>(Config.diToken);
    const { apiURL } = config.get().client;
    const { data } = await this.httpClient.get(
      `${apiURL.origin}${apiURL.pathname}/treatment-plans/${treatmentPlanId}/treatment-options`,
    );

    return data;
  }

  async ExportAssesment(assessmentId: string) {
    const config = container.get<Config>(Config.diToken);
    const { apiURL } = config.get().client;
    const data = await this.httpClient.get(
      `${apiURL.origin}${apiURL.pathname}/assessments/${assessmentId}/treatment-plan-model`,
      { responseType: 'blob' },
    );

    const response = { data: data.data, headers: data.headers };

    let fileName = '';
    let blob;

    let tempFileName = response.headers['content-disposition'];
    tempFileName = tempFileName.substring(22);

    const endIndex = tempFileName.indexOf('"');
    fileName = tempFileName.substring(0, endIndex);
    blob = response.data;

    return { fileName, blob };
  }
}
