import { injectable } from 'inversify';
import BaseService from '@core/services/base';
import { AxiosInstance } from 'axios';
import HttpClient from '@core/HttpClient';
import container from '@core/di';
import { SubscriptionPlan, SubscriptionPlanDTO } from '@shared/models/subscriptionplan';

@injectable()
export default class SubscriptionsService extends BaseService<
  SubscriptionPlanDTO,
  SubscriptionPlan
> {
  static diToken = Symbol('subscriptions-service');
  private httpClient: AxiosInstance;

  constructor() {
    super({
      domainArea: 'subscription-plans',
      Model: SubscriptionPlan,
      collection: 'clientprimaryaccount',
    });
    this.httpClient = container.get<HttpClient>(HttpClient.diToken).getInstance();
  }

  async getSubscriptions() {
    const { data } = await this.httpClient.get<SubscriptionPlan[]>(this.getURL(''));

    return data;
  }

  async update(subscriptionID: number, subscription) {
    const { data } = await this.httpClient.put(this.getURL(`${subscriptionID}`), subscription);

    return data;
  }
}
