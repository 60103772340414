import Axios from 'axios';
import { injectable, unmanaged } from 'inversify';

import { ListRequestParams, ListRequestResponse } from '@shared/types/services';
import { Id } from '@shared/types/common';

type ModelConstructor<K> = new (...args: any[]) => K;

@injectable()
export default class BaseService<T extends InstanceType<any>, K extends InstanceType<any>> {
  static diToken = Symbol('base-service');
  protected urlPrefix: string;
  protected Model: ModelConstructor<K>;
  private collection: string;

  constructor(
    @unmanaged()
    {
      domainArea,
      Model,
      collection,
    }: {
      domainArea: string;
      Model: ModelConstructor<K>;
      collection?: string;
    },
  ) {
    this.urlPrefix = `/${domainArea}`;
    this.Model = Model;
    this.collection = collection || domainArea;
  }

  getListQuery(_: ListRequestParams): string {
    return '';
  }

  getInfoFromListResponse(data: { [key: string]: ListRequestResponse<T> }) {
    const {
      [this.collection]: { items, pageInfo },
    } = data;

    return { items, pageInfo };
  }

  async getList(params: ListRequestParams) {
    const { data } = await Axios.get<{ [key: string]: ListRequestResponse<T> }>(this.urlPrefix, {
      params: { Query: this.getListQuery(params) },
    });

    const { items, pageInfo } = this.getInfoFromListResponse(data);

    return {
      pageInfo,
      items: items.map(dto => new this.Model(dto)),
    };
  }

  create(data: Partial<T>): Promise<void | any> {
    return Axios.post(this.urlPrefix, data);
  }

  async get(id: Id): Promise<K> {
    const { data } = await Axios.get<T>(`${this.urlPrefix}/${id}`);

    return new this.Model(data);
  }

  edit(id: Id, data: Partial<T>): Promise<void> {
    return Axios.put(`${this.urlPrefix}/${id}`, data);
  }

  getURL(path: Id) {
    return `${this.urlPrefix}/${path}`;
  }

  delete(id: Id): Promise<void> {
    return Axios.delete(`${this.urlPrefix}/${id}`);
  }
}
