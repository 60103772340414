import * as React from 'react';
import ROUTES from '@Admin/shared/constants/routes';
import ClientManagement from '@shared/icons/ClientManagement';
import ClientManagementActive from '@shared/icons/ClientManagementActive';
import EmployeeManagement from '@shared/icons/EmployeeManagement';
import EmployeeManagementActive from '@shared/icons/EmployeeManagementActive';
import ContentManagement from '@shared/icons/ContentManagement';
import SubscriptionManagement from '@shared/icons/SubscriptionManagement';
import Contact from '@shared/icons/Contact';
import ContactActive from '@shared/icons/ContactActive';
import Assessment from '@shared/icons/Assessment';
import AssessmentActive from '@shared/icons/AssessmentActive';
import { ROLES } from '@shared/models/employee';
import Review from '@shared/icons/Review';
import ReviewActive from '@shared/icons/ReviewActive';

export interface Item {
  id: number;
  name: string;
  route: string;
  icon: React.ReactNode;
  activeIcon: React.ReactNode;
  roles: Array<string>;
}

export const MENU_LINKS: Array<Item> = [
  {
    id: 1,
    name: 'Assessments/Treatment Plan Review',
    route: `${ROUTES.private.assessmentTreatment}/tabs/0`,
    roles: [ROLES.Clinician, ROLES.SeniorClinician, ROLES.MasterClinician],
    icon: <Review />,
    activeIcon: <ReviewActive />,
  } as Item,
  {
    id: 2,
    name: 'New Client Assignment',
    route: ROUTES.private.client,
    roles: [ROLES.SeniorClinician, ROLES.MasterClinician],
    icon: <ClientManagement />,
    activeIcon: <ClientManagementActive />,
  } as Item,
  {
    id: 3,
    name: 'Employee Management',
    route: ROUTES.private.employee,
    roles: [ROLES.Admin, ROLES.SuperAdministrator],
    icon: <EmployeeManagement />,
    activeIcon: <EmployeeManagementActive />,
  } as Item,
  {
    id: 4,
    name: 'New Assessments',
    route: ROUTES.private.assessments,
    roles: [ROLES.Clinician, ROLES.SeniorClinician, ROLES.MasterClinician],
    icon: <Assessment />,
    activeIcon: <AssessmentActive />,
  } as Item,
  {
    id: 5,
    name: 'Client Management',
    route: ROUTES.private.families,
    roles: [ROLES.Clinician, ROLES.Admin, ROLES.SeniorClinician, ROLES.MasterClinician],
    icon: <ClientManagement />,
    activeIcon: <ClientManagementActive />,
  } as Item,
  {
    id: 6,
    name: 'Content Management',
    route: `${ROUTES.private.content}/tabs/0?level=1`,
    roles: [ROLES.Admin, ROLES.MasterClinician],
    icon: <ContentManagement />,
    activeIcon: <ContentManagement />,
  } as Item,
  {
    id: 7,
    name: 'Subscription Management',
    route: ROUTES.private.subscription,
    roles: [],
    icon: <SubscriptionManagement />,
    activeIcon: <SubscriptionManagement />,
  } as Item,
  {
    id: 8,
    name: 'Contact Info and FaQ',
    route: ROUTES.private.faq,
    roles: [],
    icon: <Contact />,
    activeIcon: <ContactActive />,
  } as Item,
];
