import { injectable } from 'inversify';
import BaseService from '@core/services/base';
import { ClientPrimary, ClientPrimaryDTO } from '@shared/models/client-primary';

@injectable()
export default class ClientPrimaryService extends BaseService<ClientPrimaryDTO, ClientPrimary> {
  static diToken = Symbol('client-primary');

  constructor() {
    super({
      domainArea: 'client-primary-accounts',
      Model: ClientPrimary,
      collection: 'client-primary-accounts',
    });
  }
}
