import mixpanel from 'mixpanel-browser';
import { injectable } from 'inversify';

import container from '@core/di';
import ProfileStore from '@shared/stores/profile';
import Config from '@core/config';

@injectable()
export default class AuditService {
  static diToken = Symbol('track-events-service');
  private profileStore = container.get<ProfileStore>(ProfileStore.diToken);
  private config = container.get<Config>(Config.diToken);

  getUserParams() {
    const { email } = this.profileStore.profile;

    return {
      email,
    };
  }

  initialize() {
    const { logsPanelToken } = this.config.get().admin;

    mixpanel.init(logsPanelToken as string, {
      debug: true,
      track_pageview: true,
      persistence: 'localStorage',
    });
  }

  userIdentify = async () => {
    try {
      const profile = await this.profileStore.current();
      mixpanel.identify(profile.ssoId);
    } catch (e) {
      console.log('userIdentify error', { e });
    }
  };

  getDeviceId() {
    return mixpanel.get_property('$device_id');
  }

  trackEvent<T>(eventName: string, eventData: T): void {
    mixpanel.track(eventName, {
      'User Email': this.getUserParams().email,
      ...eventData,
    });
  }
}
