import qs, { IParseOptions } from 'qs';
import { LocationDescriptorObject } from 'history';
import omitBy from 'lodash/omitBy';
import moment from 'moment';

import history from '@shared/utils/history';
import { Queries } from '@shared/types/common';
import { DayStatus } from '@shared/constants/common';

export const getQueries = (options?: IParseOptions) =>
  qs.parse(history.location.search, { ignoreQueryPrefix: true, arrayLimit: Infinity, ...options });

export const parseQueries = (queries: string): Queries =>
  qs.parse(queries, { ignoreQueryPrefix: true, interpretNumericEntities: true });

export const getQueriesAsSearch = (queries?: Queries): string =>
  qs.stringify({ ...(queries || getQueries()) }, { addQueryPrefix: true, encode: true });

export const setQueries = (
  queries: Queries,
  replace?: boolean,
  locationArgs?: Partial<LocationDescriptorObject>,
) => {
  const args = {
    search: qs.stringify(
      omitBy(queries, value => !value),
      { addQueryPrefix: true },
    ),
    ...locationArgs,
  };

  if (replace) {
    return history.replace(args);
  }

  return history.push(args);
};

export const deleteQueries = (
  queriesToDelete?: Array<string>,
  replace?: boolean,
  locationArgs?: Partial<LocationDescriptorObject>,
) => {
  if (!queriesToDelete) {
    return setQueries({}, replace, locationArgs);
  }

  const currentQueries = getQueries();
  const finalQueries = omitBy(currentQueries, (value, key) => queriesToDelete.includes(key));

  setQueries(finalQueries, replace, locationArgs);
};

export const getTestAttr = (module: string, elementDescription: string, elementName: string) => ({
  'data-test-id': `${module}-${elementDescription}-${elementName}`,
});

export const formatDate = (
  date: string | Date,
  format: string = 'MMM DD, YYYY',
  utc: boolean = true,
) => {
  if (!date) {
    return '';
  }

  if (utc) {
    return moment.utc(date).format(format);
  }

  return moment(date).format(format);
};

export const getRandomInt = (min: number, max: number) =>
  min + Math.floor(Math.random() * (max - min + 1));

export const getColor = (
  hue: number,
  saturation: number = 100,
  lightness: number = 40,
  opacity: number = 1,
): string => {
  const hueRange = 360;

  return `hsla(${hue % hueRange}, ${saturation}%, ${lightness}%, ${opacity})`;
};

export const toLowerCaseFirst = (str: string) => str.charAt(0).toLowerCase() + str.substr(1);

export const parseBytes = (bytes: number, decimals = 2): { amount: number; unit: string } => {
  const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  if (bytes === 0) {
    return {
      amount: 0,
      unit: units[0],
    };
  }

  const k = 1024;
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return {
    amount: parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)),
    unit: units[i],
  };
};

export const generateId = () => new Date().getTime();

export const getMaxDate = (items: Array<any>, dateProperty?: string): string => {
  const getItemsMaxDate = (dates: Array<string>) => {
    const normalizedDates = dates.map(date => new Date(date).getTime());
    const maxItemDate = Math.max(...normalizedDates);

    return new Date(maxItemDate).toISOString();
  };

  if (dateProperty) {
    const itemsDates = items.map(item => item[dateProperty]);

    return getItemsMaxDate(itemsDates);
  }

  return getItemsMaxDate(items);
};

export const serialize = (data: any) => {
  return JSON.stringify(data);
};

export const deserialize = (str: null | string, defaultValue: any = {}) => {
  try {
    return JSON.parse(str || '');
  } catch {
    return defaultValue;
  }
};

type Key = number | string;

export const getDataFromStorageUnit = (storageKey: Key): { [key: string]: any } | {} => {
  const storageUnit = localStorage.getItem(String(storageKey));

  return deserialize(storageUnit);
};

export const setDataToStorageUnit = (storageKey: Key, dataKey: Key, data?: any) => {
  const currentData = localStorage.getItem(String(storageKey));
  const deserializedData = deserialize(currentData);

  const nextData = {
    ...deserializedData,
    [dataKey]: data,
  };

  return localStorage.setItem(String(storageKey), serialize(nextData));
};

export const deleteStorageUnit = (storageKey: Key) => {
  localStorage.removeItem(String(storageKey));
};

const BREAD_CRUMBS_KEY = 'VI_bread_crumbs';

export const getBreadCrumbs = (key: string): LocationDescriptorObject | undefined => {
  return getDataFromStorageUnit(BREAD_CRUMBS_KEY)[key];
};

export const setBreadCrumbs = (key: string, location: LocationDescriptorObject) => {
  setDataToStorageUnit(BREAD_CRUMBS_KEY, key, location);
};

export const deleteBreadCrumbs = (key: string) => {
  setDataToStorageUnit(BREAD_CRUMBS_KEY, key, undefined);
};

export const getBase64FromFile = (file: File): Promise<string> => {
  const reader = new FileReader();

  return new Promise(resolve => {
    reader.onload = ({ target }) => {
      if (target) {
        resolve(String(target.result));
      }
    };

    reader.readAsDataURL(file);
  });
};

export const getBase64FromUrl = (url: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    if (!url) {
      resolve('');

      return;
    }

    const canvas = document.createElement('canvas');
    const canvasCtx = canvas.getContext('2d');
    const img = new Image();

    img.crossOrigin = 'anonymous';

    img.onload = () => {
      canvas.height = img.height;
      canvas.width = img.width;
      canvasCtx?.drawImage(img, 0, 0);

      const dataURL = canvas.toDataURL();

      resolve(dataURL);
    };

    img.onerror = () => {
      reject('Something went wrong with the image');
    };

    img.src = url;
  });
};

export const getDefaultError = (errors: { [key: string]: Array<string> } = {}) => {
  const allErrors = Object.values(errors);

  return allErrors[0] || 'Something went wrong';
};

export const getDayStatus = (date: string) => {
  const extendedDate = moment.utc(date);

  if (extendedDate.isSame(moment().utc(), 'd')) {
    return DayStatus.today;
  }

  if (extendedDate.isSame(moment().utc().add(1, 'days'), 'd')) {
    return DayStatus.tomorrow;
  }

  if (extendedDate.isBefore(moment().utc(), 'd')) {
    return DayStatus.past;
  }

  return DayStatus.default;
};

export const isAfter = (compareToDate: string, comparableDate: string) => {
  return moment.utc(comparableDate).isAfter(compareToDate, 'day');
};

export const tryExtractTimezoneOffset = (timeZone?: string) => {
  if (!timeZone) {
    return '';
  }

  const regex = /^([+-]?\d{2}):/;
  const match = timeZone.match(regex);

  if (match) {
    return Number(match[1]) >= 0 ? `+${Number(match[1])}` : Number(match[1]).toString();
  }

  return timeZone;
};
