import * as React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

import styles from './Review.styles';

interface ReviewProps extends Omit<SvgIconProps, 'classes'>, WithStyles<typeof styles> {}

const Review: React.FC<ReviewProps> = ({ classes }) => {
  return (
    <SvgIcon
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      classes={{ root: classes.root }}
    >
      <rect width="18" height="18" rx="6" fill="#F7F7FA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.30538 2.78494C4.30538 1.99512 4.94566 1.35484 5.73548 1.35484C6.52536 1.35484 7.16556 1.99512 7.16556 2.78494C7.16556 3.15908 7.46886 3.46236 7.84298 3.46236H9.95057C10.042 3.46236 10.1161 3.5365 10.1161 3.62796V4.89248C10.1161 5.26662 10.4194 5.56992 10.7935 5.56992C11.1677 5.56992 11.471 5.26662 11.471 4.89248V3.62796C11.471 2.78825 10.7902 2.10752 9.95057 2.10752H8.43748C8.1349 0.896822 7.03992 0 5.73548 0C4.43105 0 3.33606 0.896822 3.0335 2.10752H1.52043C0.680716 2.10752 0 2.78825 0 3.62796V12.4796C0 13.3192 0.680716 14 1.52043 14H5.73548C6.1096 14 6.4129 13.6967 6.4129 13.3226C6.4129 12.9485 6.1096 12.6452 5.73548 12.6452H1.52043C1.42898 12.6452 1.35484 12.571 1.35484 12.4796V3.62796C1.35484 3.5365 1.42898 3.46236 1.52043 3.46236H3.62796C4.00209 3.46236 4.30538 3.15908 4.30538 2.78494ZM9.52903 5.90105C7.52541 5.90105 5.90105 7.52541 5.90105 9.52903C5.90105 11.5327 7.52541 13.157 9.52903 13.157C10.286 13.157 10.9889 12.9252 11.5705 12.5286L12.8436 13.8016C13.1082 14.0661 13.537 14.0661 13.8016 13.8016C14.0661 13.537 14.0661 13.1082 13.8016 12.8436L12.5286 11.5705C12.9252 10.9889 13.157 10.286 13.157 9.52903C13.157 7.52541 11.5327 5.90105 9.52903 5.90105ZM11.1477 11.125C11.5523 10.7146 11.8022 10.151 11.8022 9.52903C11.8022 8.27364 10.7844 7.25588 9.52903 7.25588C8.27364 7.25588 7.25588 8.27364 7.25588 9.52903C7.25588 10.7844 8.27364 11.8022 9.52903 11.8022C10.151 11.8022 10.7146 11.5523 11.125 11.1477L11.1362 11.1362L11.1477 11.125Z"
        fill="#13A9BA"
      />
    </SvgIcon>
  );
};

export default withStyles(styles)(Review);
