export interface ClinicianDTO {
  assessmentId: string;
  childId: string;
  clientPrimaryAccountId: string;
  clientPrimaryAccountFirstName: string;
  clientPrimaryAccountLastName: string;
  submitted: string;
  childName: string;
  country: string;
}

export class Clinician {
  aassessmentId: string;
  childId: string;
  clientPrimaryAccountId: string;
  clientPrimaryAccountFirstName: string;
  clientPrimaryAccountLastName: string;
  submitted: string;
  childName: string;
  country: string;

  constructor(dto: ClinicianDTO) {
    this.update(dto);
  }

  get asJson(): ClinicianDTO {
    return {
      assessmentId: this.aassessmentId,
      childId: this.childId,
      clientPrimaryAccountId: this.clientPrimaryAccountId,
      clientPrimaryAccountFirstName: this.clientPrimaryAccountFirstName,
      clientPrimaryAccountLastName: this.clientPrimaryAccountLastName,
      submitted: this.submitted,
      childName: this.submitted,
      country: this.country,
    };
  }

  update(newData: Partial<ClinicianDTO>): ClinicianDTO {
    const allData = { ...this.asJson, ...newData };

    Object.assign(this, allData);

    return this.asJson;
  }
}
