export interface PaymentMethodDTO {
  last4: string;
  country: string;
  address: string;
  isExpired: boolean;
  brand: string;
  paymentProviderPaymentMethodId: string;
}

export class PaymentMethod implements PaymentMethodDTO {
  last4: string;
  country: string;
  address: string;
  isExpired: boolean;
  brand: string;
  paymentProviderPaymentMethodId: string;

  constructor(dto: PaymentMethodDTO) {
    this.update(dto);
  }

  get asJson(): PaymentMethodDTO {
    return {
      last4: this.last4,
      country: this.country,
      address: this.address,
      isExpired: this.isExpired,
      brand: this.brand,
      paymentProviderPaymentMethodId: this.paymentProviderPaymentMethodId,
    };
  }

  update(newData: Partial<PaymentMethodDTO>): PaymentMethodDTO {
    const allData = { ...this.asJson, ...newData };

    Object.assign(this, allData);

    return this.asJson;
  }
}
