import { injectable } from 'inversify';

import BaseService from '@core/services/base';
import Axios from 'axios';
import { Profile, ProfileDTO } from '@shared/models/profile';

@injectable()
export default class ProfileService extends BaseService<ProfileDTO, Profile> {
  static diToken = Symbol('profile-service');

  constructor() {
    super({ domainArea: 'user-accounts', Model: Profile });
  }

  async getProfile() {
    const { data } = await Axios.get<Profile>(this.getURL('current'));

    return data;
  }

  update(data) {
    return Axios.put<Profile>(this.getURL('current'), data);
  }

  removeAvatar() {
    return Axios.delete<Profile>(this.getURL('current/user-image-profile'));
  }

  uploadAvatar(formData) {
    return Axios.put(this.getURL('current/user-image-profile'), formData);
  }

  removeUser(ssoId: string) {
    return Axios.delete(this.getURL(`${ssoId}`));
  }
}
