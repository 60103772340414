export enum REPORT_TYPE {
  DAILY = 'Daily',
  MONTHLY = 'Monthly',
}

export interface LifeQualityItemDTO {
  authorName: string;
  clientPrimaryAccountId: SVGAnimatedNumberList;
  creationDate: string;
  id: number;
  treatmentEndDate?: string;
  treatmentStartDate: string;
  type: REPORT_TYPE;
}

export class LifeQualityItem {
  authorName: string;
  clientPrimaryAccountId: SVGAnimatedNumberList;
  creationDate: string;
  id: number;
  treatmentEndDate?: string;
  treatmentStartDate: string;
  type: REPORT_TYPE;

  constructor(dto: LifeQualityItemDTO) {
    this.update(dto);
  }

  get asJson(): LifeQualityItemDTO {
    return {
      authorName: this.authorName,
      clientPrimaryAccountId: this.clientPrimaryAccountId,
      creationDate: this.creationDate,
      id: this.id,
      treatmentEndDate: this.treatmentEndDate,
      treatmentStartDate: this.treatmentStartDate,
      type: this.type,
    };
  }

  update(newData: Partial<LifeQualityItemDTO>): LifeQualityItemDTO {
    const allData = { ...this.asJson, ...newData };

    Object.assign(this, allData);

    return this.asJson;
  }
}
