import * as React from 'react';
import { observer } from 'mobx-react';

import { AuthStore } from '@shared/stores';
import container from '@core/di';
import Progress from './components/Progress/Progress';
import Logo from '@public/assets/images/logo.png';

type SignoutProps = {
  type?: 'autoRedirect' | 'withMessage';
  message?: React.ReactNode;
};

@observer
class Signout extends React.Component<SignoutProps> {
  static defaultProps = {
    type: 'autoRedirect',
  };

  private authStore = container.get<AuthStore>(AuthStore.diToken);

  componentDidMount() {
    if (this.props.type === 'autoRedirect') {
      this.authStore.reset();
    }
  }

  render() {
    const { message } = this.props;

    return (
      <div>
        <Progress />
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 250 }}>
          <img src={Logo} height={160} alt="Logo" />
        </div>
        {message}
      </div>
    );
  }
}

export default Signout;
