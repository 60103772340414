import { injectable } from 'inversify';
import container from '@core/di';
import BaseStore from '@core/stores/base';
import { observable } from 'mobx';
import { Clinician, ClinicianDTO } from '@shared/models/clinician';
import ClinicianService from '@shared/services/clinician';

@injectable()
export default class ClinicianStore extends BaseStore<ClinicianDTO, Clinician, ClinicianService> {
  static diToken = Symbol('clinician');

  @observable notAssignedAssessmentCount = 0;
  @observable totalNotApprovedEntitiesCount = 0;

  constructor() {
    super({ service: container.get<ClinicianService>(ClinicianService.diToken) });

    this.loading = {
      list: false,
      item: false,
    };
  }

  getFirstAssignedAssessments = async (page, pageSize) => {
    return await this.service.getFirstAssignedAssessments(page, pageSize);
  };

  getInitialPlanReview = async (page: number, pageSize: number) => {
    return await this.service.getInitialPlanReview(page, pageSize);
  };

  updateNotAssignedAssessmentsInfo = async () => {
    const response = await this.service.getAssignedAssessmentsInfo();
    this.notAssignedAssessmentCount = response.notAssignedAssessmentCount;
  };

  getSubsiquentAssignedAssessments = async (page, pageSize) => {
    return await this.service.getSubsiquentAssignedAssessments(page, pageSize);
  };

  getAssessmentsTreatmentPlansInfo = async () => {
    const response = await this.service.getAssessmentsTreatmentPlansInfo();
    this.totalNotApprovedEntitiesCount = response.totalNotAprovedEntitiesCount;

    return response;
  };
}
