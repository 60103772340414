export enum ASSESSMENT_STATUS {
  APPROVED = 'Approved',
  NEW = 'New',
  SUBMITTED = 'Submitted',
}

export enum ASSESSMENT_STARTED_BY {
  CLIENT_PRIMARY_ACCOUNT = 'ClientPrimaryAccount',
  CLINICIAN = 'Clinician',
}

export enum ASSESSMENT_STARTED_BY_STATUS {
  CLIENT_PRIMARY_ACCOUNT = 'ClientPrimaryAccount',
  CLINICIAN = 'Clinician',
}

export enum ASSESSMENT_OWNER {
  Clinician = 'Clinician',
  ClientPrimaryAccount = 'ClientPrimaryAccount',
}
