import { injectable } from 'inversify';
import container from '@core/di';
import BaseStore from '@core/stores/base';
import { action, computed, observable } from 'mobx';
import { SubscriptionPlan, SubscriptionPlanDTO } from '@shared/models/subscriptionplan';
import SubscriptionsService from '@shared/services/subscriptions';

@injectable()
export default class SubscriptionsStore extends BaseStore<
  SubscriptionPlanDTO,
  SubscriptionPlan,
  SubscriptionsService
> {
  static diToken = Symbol('subscriptions-store');

  @observable private _subscriptions: SubscriptionPlan[] = [];
  @observable private _isFetched = false;

  constructor() {
    super({
      service: container.get<SubscriptionsService>(SubscriptionsService.diToken),
    });

    this.loading = {
      list: false,
      item: false,
    };
  }

  @action current = async () => {
    const subscriptions = await this.getSubscriptions();
    this._subscriptions = subscriptions;
    this._isFetched = true;

    return subscriptions;
  };

  @computed get subscriptions(): SubscriptionPlan[] {
    return this._subscriptions;
  }

  set subscriptions(items: SubscriptionPlan[]) {
    this._subscriptions = items;
  }

  @computed get isFetched(): boolean {
    return this._isFetched;
  }

  @action getSubscriptions = async () => {
    return await this.service.getSubscriptions();
  };

  @action update = async (subscriptionID: number, subscription) => {
    return await this.service.update(subscriptionID, subscription);
  };
}
