import * as React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

import styles from './Contact.styles';

interface ContactProps extends Omit<SvgIconProps, 'classes'>, WithStyles<typeof styles> {}

const Contact: React.FC<ContactProps> = ({ classes }) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      classes={{ root: classes.root }}
    >
      <rect width="24" height="24" rx="6" fill="#F7F7FA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.27273 7H17.7273C18.4273 7 19 7.57273 19 8.27273V16.5455C19 17.2455 18.4273 17.8182 17.7273 17.8182H6.27273C5.57273 17.8182 5 17.2455 5 16.5455V8.27273C5 7.57273 5.57273 7 6.27273 7ZM6.27273 8.27273V16.5455H11.3636V8.27273H6.27273ZM17.7273 16.5455H12.6364V8.27273H17.7273V16.5455ZM17.0909 10.5H13.2727V11.4545H17.0909V10.5ZM13.2727 12.0909H17.0909V13.0455H13.2727V12.0909ZM17.0909 13.6818H13.2727V14.6364H17.0909V13.6818Z"
        fill="#13A9BA"
      />
    </SvgIcon>
  );
};

export default withStyles(styles)(Contact);
