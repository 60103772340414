interface AppConfig {
  environment?: EnvironmentType;
}

interface AppWindow extends Window {
  familyHopeCentre?: AppConfig;
}

export const getEnvironment = () => {
  return (window as AppWindow)?.familyHopeCentre?.environment;
};
