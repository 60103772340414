import { createStyles } from '@material-ui/core';
import {Theme} from '@material-ui/core/styles';

export default ({ spacing, palette: { colors } }: Theme) => createStyles({
  root: {
    height: '100%',
    flexGrow: 1,
  },
  navBar: {
    width: '100%',
    height: '30px',
    backgroundColor : '#000000',
    color:'white',
  },
  menu: {
    width: '245px',
    fontSize: '14px',
    padding: '1px'
  },
  content: {
    display: 'flex',
    height: '100%'
  },
  modules: {
    width: '100%',
    // backgroundColor: '#E5E5E5',
    // height: '100%',
    // padding: '5px'

  },
  title: {
    flexGrow: 1,
  },

  // module: {
  //   height: 'calc(100vh - 80px)',
  //   overflowX: 'hidden',
  //   overflowY: 'auto',
  //   position: 'relative',
  //   width: '100%',
  // },
});
