import { AxiosInstance } from 'axios';
import { injectable } from 'inversify';
import BaseService from '@core/services/base';
import Config from '@core/config';
import HttpClient from '@core/HttpClient';
import container from '@core/di';
import { Assessment, AssessmentDTO } from '@shared/models/assessment';
import {
  Evaluation,
  AbilitiesNote,
  AdditionalCriteriaNote,
  EvaluationReviewStatus,
} from '@shared/models/assessmentdetails';
import { AgeLevelType } from '@shared/models/evaluations';
import { Video } from '@shared/models/video';

@injectable()
export default class AssessmentsEvaluationsService extends BaseService<AssessmentDTO, Assessment> {
  static diToken = Symbol('assessments-evaluations-service');
  private httpClient: AxiosInstance;

  constructor() {
    super({
      domainArea: 'assessments',
      Model: Assessment,
      collection: 'assessments',
    });
    this.httpClient = container.get<HttpClient>(HttpClient.diToken).getInstance();
  }

  async getMatrixAssessments() {
    const config = container.get<Config>(Config.diToken);
    const { apiURL } = config.get().client;

    const { data } = await this.httpClient({
      method: 'get',
      baseURL: `${apiURL.origin}${apiURL.pathname}`,
      url: 'assessments/matrix',
    });

    return data;
  }

  async getClinicalCriteria() {
    const config = container.get<Config>(Config.diToken);
    const { apiURL } = config.get().client;

    const { data } = await this.httpClient({
      method: 'get',
      baseURL: `${apiURL.origin}${apiURL.pathname}`,
      url: 'clinical-criteria',
    });

    return data;
  }

  async getEvaluations(assessmentId: number) {
    const config = container.get<Config>(Config.diToken);
    const { apiURL } = config.get().client;

    const { data } = await this.httpClient({
      method: 'get',
      baseURL: `${apiURL.origin}${apiURL.pathname}`,
      url: `assessments/${assessmentId}`,
    });

    return data;
  }

  async getCurrentClinicalCriteria(assessmentId: number) {
    const config = container.get<Config>(Config.diToken);
    const { apiURL } = config.get().client;

    const { data } = await this.httpClient({
      method: 'get',
      baseURL: `${apiURL.origin}${apiURL.pathname}`,
      url: `assessments/${assessmentId}/clinical-criteria`,
    });

    return data;
  }

  async getAbilitiesNoteHistory(childId: number, assessmentId: string, brainFunctionId: number) {
    const config = container.get<Config>(Config.diToken);
    const { apiURL } = config.get().client;

    const { data } = await this.httpClient({
      method: 'get',
      baseURL: `${apiURL.origin}${apiURL.pathname}`,
      url: `children/${childId}/assessments/${assessmentId}/brain-functions/${brainFunctionId}/notes`,
    });

    return data;
  }

  async getAdditionalCriteriaNoteHistory(
    childId: number,
    assessmentId: string,
    clinicalCriterionId: number,
  ) {
    const config = container.get<Config>(Config.diToken);
    const { apiURL } = config.get().client;

    const { data } = await this.httpClient({
      method: 'get',
      baseURL: `${apiURL.origin}${apiURL.pathname}`,
      url: `children/${childId}/assessments/${assessmentId}/clinical-criteria/${clinicalCriterionId}/notes`,
    });

    return data;
  }

  async getAbilitiesNotes(assessmentId: number) {
    const config = container.get<Config>(Config.diToken);
    const { apiURL } = config.get().client;

    const { data } = await this.httpClient({
      method: 'get',
      baseURL: `${apiURL.origin}${apiURL.pathname}`,
      url: `assessments/${assessmentId}/notes`,
    });

    return data;
  }

  async getAdditionalCriteriaNotes(assessmentId: number) {
    const config = container.get<Config>(Config.diToken);
    const { apiURL } = config.get().client;

    const { data } = await this.httpClient({
      method: 'get',
      baseURL: `${apiURL.origin}${apiURL.pathname}`,
      url: `assessments/${assessmentId}/clinical-criteria/notes`,
    });

    return data;
  }

  async editAbilitiesNote(assessmentId: string, notes: AbilitiesNote) {
    const config = container.get<Config>(Config.diToken);
    const { apiURL } = config.get().client;

    const { data } = await this.httpClient({
      method: 'put',
      baseURL: `${apiURL.origin}${apiURL.pathname}`,
      url: `assessments/${assessmentId}/notes`,
      data: [notes],
    });

    return data;
  }

  async editAdditionalCriteriaNote(assessmentId: string, notes: AdditionalCriteriaNote) {
    const config = container.get<Config>(Config.diToken);
    const { apiURL } = config.get().client;

    const { data } = await this.httpClient({
      method: 'put',
      baseURL: `${apiURL.origin}${apiURL.pathname}`,
      url: `assessments/${assessmentId}/clinical-criteria/notes`,
      data: [notes],
    });

    return data;
  }

  async editEvaluationRating(
    clientPrimaryAccountId: number,
    childId: number,
    brainFunctionEvaluations: { brainFunctionEvaluations: Evaluation[] },
  ) {
    const config = container.get<Config>(Config.diToken);
    const { apiURL } = config.get().client;

    const { data } = await this.httpClient({
      method: 'put',
      baseURL: `${apiURL.origin}${apiURL.pathname}`,
      url: `client-primary-accounts/${clientPrimaryAccountId}/children/${childId}/assessments/current/brain-functions`,
      data: brainFunctionEvaluations,
    });

    return data;
  }

  async reviewBrainFunctions(
    clientPrimaryAccountId: number,
    childId: number,
    brainFunctionIds: number[],
    reviewStatus: EvaluationReviewStatus,
  ) {
    const config = container.get<Config>(Config.diToken);
    const { apiURL } = config.get().client;

    const { data } = await this.httpClient({
      method: 'put',
      baseURL: `${apiURL.origin}${apiURL.pathname}`,
      url: `client-primary-accounts/${clientPrimaryAccountId}/children/${childId}/assessments/current/brain-functions/review`,
      data: {
        brainFunctionsEvaluationReviewStatus: brainFunctionIds.map(id => ({
          reviewStatus,
          brainFunctionId: id,
        })),
      },
    });

    return data;
  }

  async reviewAdditionalCriteria(
    clientPrimaryAccountId: number,
    childId: number,
    clinicalCriterionIds: number[],
    reviewStatus: EvaluationReviewStatus,
  ) {
    const config = container.get<Config>(Config.diToken);
    const { apiURL } = config.get().client;

    const { data } = await this.httpClient({
      method: 'put',
      baseURL: `${apiURL.origin}${apiURL.pathname}`,
      url: `client-primary-accounts/${clientPrimaryAccountId}/children/${childId}/assessments/current/clinical-criteria/review`,
      data: {
        clinicalCriteriaEvaluationReviewStatus: clinicalCriterionIds.map(id => ({
          reviewStatus,
          clinicalCriterionId: id,
        })),
      },
    });

    return data;
  }

  async markDomainAsNotYetAchieved(
    clientPrimaryAccountId: number,
    childId: number,
    brainDomain: string,
    currentBrainLevelId: number,
  ) {
    const config = container.get<Config>(Config.diToken);
    const { apiURL } = config.get().client;

    const { data } = await this.httpClient({
      method: 'put',
      baseURL: `${apiURL.origin}${apiURL.pathname}`,
      url: `client-primary-accounts/${clientPrimaryAccountId}/children/${childId}/assessments/current/mark-not-yet-achieved`,
      data: {
        brainDomain,
        currentBrainLevelId,
      },
    });

    return data;
  }

  async editChildAge(clientPrimaryAccountId: number, childId: number, ageAtLevel: AgeLevelType) {
    const config = container.get<Config>(Config.diToken);
    const { apiURL } = config.get().client;

    const { data } = await this.httpClient({
      method: 'put',
      baseURL: `${apiURL.origin}${apiURL.pathname}`,
      url: `client-primary-accounts/${clientPrimaryAccountId}/children/${childId}/assessments/current/child-age-level-eight`,
      data: ageAtLevel,
    });

    return data;
  }

  async editAdditionalCriteria(clientPrimaryAccountId: number, childId: number, ids: any) {
    const config = container.get<Config>(Config.diToken);
    const { apiURL } = config.get().client;

    const { data } = await this.httpClient({
      method: 'put',
      baseURL: `${apiURL.origin}${apiURL.pathname}`,
      url: `client-primary-accounts/${clientPrimaryAccountId}/children/${childId}/assessments/current/clinical-criteria`,
      data: {
        clinicalCriterionOptionIds: ids,
      },
    });

    return data;
  }

  async createNewAssessment(clientPrimaryAccountId: number, childId: number) {
    const config = container.get<Config>(Config.diToken);
    const { apiURL } = config.get().client;

    const { data } = await this.httpClient({
      method: 'post',
      baseURL: `${apiURL.origin}${apiURL.pathname}`,
      url: `client-primary-accounts/${clientPrimaryAccountId}/children/${childId}/assessments`,
    });

    return data;
  }

  async approveAssessment(clientPrimaryAccountId: string, childId: string) {
    const config = container.get<Config>(Config.diToken);
    const { apiURL } = config.get().client;
    const { data } = await this.httpClient({
      method: 'put',
      baseURL: `${apiURL.origin}${apiURL.pathname}`,
      url: `client-primary-accounts/${clientPrimaryAccountId}/children/${childId}/assessments/current/approve`,
    });

    return data;
  }

  async submitAssessment(clientPrimaryAccountId: string, childId: string) {
    const config = container.get<Config>(Config.diToken);
    const { apiURL } = config.get().client;
    const { data } = await this.httpClient({
      method: 'put',
      baseURL: `${apiURL.origin}${apiURL.pathname}`,
      url: `client-primary-accounts/${clientPrimaryAccountId}/children/${childId}/assessments/current/submit`,
    });

    return data;
  }

  /** Submit subsequent assessment created by clinician, review all functions and approve */
  async finalizeAssessment(clientPrimaryAccountId: string, childId: string) {
    const config = container.get<Config>(Config.diToken);
    const { apiURL } = config.get().client;
    const { data } = await this.httpClient({
      method: 'put',
      baseURL: `${apiURL.origin}${apiURL.pathname}`,
      url: `client-primary-accounts/${clientPrimaryAccountId}/children/${childId}/assessments/current/finalize`,
    });

    return data;
  }

  async getAssessmentStatus(clientPrimaryAccountId: string, childId: number) {
    const config = container.get<Config>(Config.diToken);
    const { apiURL } = config.get().client;
    const { data } = await this.httpClient({
      method: 'get',
      baseURL: `${apiURL.origin}${apiURL.pathname}`,
      url: `client-primary-accounts/${clientPrimaryAccountId}/children/${childId}/assessments/current-status`,
    });

    return data;
  }

  async assignAssessmentToClinician(
    assessmentId: string,
    clientPrimaryAccountId: string,
    childId: number,
  ) {
    const config = container.get<Config>(Config.diToken);
    const { apiURL } = config.get().client;
    const { data } = await this.httpClient({
      method: 'put',
      baseURL: `${apiURL.origin}${apiURL.pathname}`,
      url: `client-primary-accounts/${clientPrimaryAccountId}/children/${childId}/assessments/${assessmentId}/assign`,
    });

    return data;
  }

  async getResources(brainFunctionId: number) {
    const { data } = await this.httpClient.get<Video>(
      this.getURL(`brain-functions/${brainFunctionId}/video`),
    );

    return data;
  }
}
