import { injectable } from 'inversify';
import BaseService from '@core/services/base';
import Axios, { AxiosInstance } from 'axios';
import HttpClient from '@core/HttpClient';
import container from '@core/di';
import {
  Intervention,
  InterventionDTO,
  InterventionVideoUpdate,
} from '@shared/models/interventions';
import { InterventionVideo } from '@shared/models/interventionvideos';
import { InterventionFile } from '@shared/models/interventionfiles';

@injectable()
export default class InterventionsService extends BaseService<InterventionDTO, Intervention> {
  static diToken = Symbol('interventions-service');
  private httpClient: AxiosInstance;
  private cancelToken = Axios.CancelToken;
  private source = this.cancelToken.source();

  constructor() {
    super({
      domainArea: 'therapeutic-interventions',
      Model: Intervention,
      collection: '',
    });

    this.httpClient = container.get<HttpClient>(HttpClient.diToken).getInstance();
  }

  async getTerapeuticInterventions() {
    const { data } = await this.httpClient.get<Intervention[]>(this.getURL(''));

    return data;
  }

  public cancelUpload() {
    this.source.cancel();
  }

  // videos
  async getVideos(uId) {
    const { data } = await this.httpClient.get<InterventionVideo[]>(this.getURL(`${uId}/videos`));

    return data;
  }

  async uploadVideo(id, formData, progressCallback) {
    this.cancelToken = Axios.CancelToken;
    this.source = this.cancelToken.source();

    return this.httpClient.post(this.getURL(`${id}/videos`), formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
      onUploadProgress: progressData => {
        progressCallback(Math.round((100 * progressData.loaded) / progressData.total));
      },
      cancelToken: this.source.token,
    });
  }

  async updateVideo(interventionId, videoId, payload: InterventionVideoUpdate) {
    const { data } = await this.httpClient.put(
      this.getURL(`${interventionId}/videos/${videoId}`),
      payload,
    );

    return data;
  }

  async deleteVideo(interventionId, videoId) {
    const { data } = await this.httpClient.delete(
      this.getURL(`${interventionId}/videos/${videoId}`),
    );

    return data;
  }

  // files
  async getFiles(uId) {
    const { data } = await this.httpClient.get<InterventionFile[]>(this.getURL(`${uId}/files`));

    return data;
  }

  async uploadFile(id, formData, progressCallback) {
    this.cancelToken = Axios.CancelToken;
    this.source = this.cancelToken.source();

    return this.httpClient.post(this.getURL(`${id}/files`), formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
      onUploadProgress: progressData => {
        progressCallback(Math.round((100 * progressData.loaded) / progressData.total));
      },
      cancelToken: this.source.token,
    });
  }

  async updateFile(interventionId, videoId, payload: InterventionVideoUpdate) {
    const { data } = await this.httpClient.put(
      this.getURL(`${interventionId}/files/${videoId}`),
      payload,
    );

    return data;
  }

  async deleteFile(interventionId, videoId) {
    const { data } = await this.httpClient.delete(
      this.getURL(`${interventionId}/files/${videoId}`),
    );

    return data;
  }
}
