import { observable } from 'mobx';

export interface FamilyDTO {
  childName: string;
  country: string;
  created: string;
  deleted: boolean;
  email: string;
  firstName: string;
  id: number;
  lastName: string;
  subscriptionPlan: string;
  subscriptionPlanId: number;
  uId: string;
  degreeOfNeurologicalFunction: string;
  assignedUserFirstName: string;
  assignedUserLastName: string;
}

export class Family implements FamilyDTO {
  childName: string;
  country: string;
  created: string;
  deleted: boolean;
  @observable email: string;
  firstName: string;
  id: number;
  lastName: string;
  subscriptionPlan: string;
  subscriptionPlanId: number;
  uId: string;
  degreeOfNeurologicalFunction: string;
  assignedUserFirstName: string;
  assignedUserLastName: string;

  constructor(dto: FamilyDTO) {
    this.update(dto);
  }

  get asJson(): FamilyDTO {
    return {
      id: this.id,
      uId: this.uId,
      childName: this.childName,
      subscriptionPlan: this.subscriptionPlan,
      subscriptionPlanId: this.subscriptionPlanId,
      country: this.country,
      created: this.created,
      deleted: this.deleted,
      lastName: this.lastName,
      firstName: this.firstName,
      email: this.email,
      degreeOfNeurologicalFunction: this.degreeOfNeurologicalFunction,
      assignedUserFirstName: this.assignedUserFirstName,
      assignedUserLastName: this.assignedUserLastName,
    };
  }

  update(newData: Partial<FamilyDTO>): FamilyDTO {
    const allData = { ...this.asJson, ...newData };

    Object.assign(this, allData);

    return this.asJson;
  }
}
