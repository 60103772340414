import * as React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

import styles from './ContentManagement.styles';

interface ContentManagementProps extends Omit<SvgIconProps, 'classes'>, WithStyles<typeof styles> {}

const ContentManagement: React.FC<ContentManagementProps> = ({ classes }) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      classes={{ root: classes.root }}
    >
      <rect width="24" height="24" rx="6" fill="#F7F7FA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7273 6H6.27273C5.63636 6 5 6.63636 5 7.27273V16.1818C5 16.8818 5.57273 17.4545 6.27273 17.4545H17.7273C18.3636 17.4545 19 16.8182 19 16.1818V7.27273C19 6.63636 18.3636 6 17.7273 6ZM17.7273 16.131C17.7185 16.1443 17.7034 16.1576 17.6908 16.1688L17.6908 16.1688C17.6853 16.1736 17.6803 16.178 17.6764 16.1819H6.2728V7.32371L6.32371 7.2728H17.6701C17.6834 7.28167 17.6967 7.29673 17.7078 7.30934L17.7078 7.30935L17.7079 7.30937C17.7127 7.31484 17.7171 7.31986 17.721 7.32371V16.131H17.7273ZM9.77287 12.0453L11.3638 13.9608L13.5911 11.0908L16.4547 14.909H7.5456L9.77287 12.0453Z"
        fill="#13A9BA"
      />
    </SvgIcon>
  );
};

export default withStyles(styles)(ContentManagement);
