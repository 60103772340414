import { ROLES } from './employee';

// TODO: make own model, (familyinfo )
export type Country = { countryName: string; id: number };

export interface ProfileDTO {
  id: number;
  ssoId: string;
  email: string;
  firstName: string;
  lastName: string;
  creationDate: string;
  active: true;
  role: ROLES;
  country: Country;
  phoneNumber: string;
  profileImageUrl?: string;
}

export class Profile {
  id: number;
  ssoId: string;
  email: string;
  firstName: string;
  lastName: string;
  creationDate: string;
  active: true;
  role: ROLES;
  country: Country;
  phoneNumber: string;
  profileImageUrl?: string;

  constructor(dto: ProfileDTO) {
    this.update(dto);
  }

  get asJson(): ProfileDTO {
    return {
      id: this.id,
      ssoId: this.ssoId,
      email: this.email,
      firstName: this.firstName,
      lastName: this.lastName,
      creationDate: this.creationDate,
      active: this.active,
      role: this.role,
      country: this.country,
      phoneNumber: this.phoneNumber,
      profileImageUrl: this.profileImageUrl,
    };
  }

  update(newData: Partial<ProfileDTO>): ProfileDTO {
    const allData = { ...this.asJson, ...newData };

    Object.assign(this, allData);

    return this.asJson;
  }
}
